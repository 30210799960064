import { Fragment } from "react";
import { useMedia } from "react-use";
import { Button, Colors, mediaQuery, Text } from "@gemini-ui/design-system";
import { testIds } from "@gemini-ui/pages/register/testIds";
import { trackStartRegistration } from "@gemini-ui/pages/SignIn/trackStartRegistration";
import { useIntl } from "@gemini-ui/utils/intl";

type Props = {
  oauthClientId?: string;
};

const registerUrl = jsRoutes.com.gemini.web.server.onboarding.controllers.services.RegistrationController.get().url;

export default function RightNav({ oauthClientId }: Props) {
  const { intl } = useIntl();
  const isMobile = useMedia(mediaQuery.mobileXsDown);
  const formattedRegisterUrl = oauthClientId ? `${registerUrl}?clientId=${oauthClientId}` : registerUrl;

  return (
    <Fragment>
      {!isMobile && (
        <Text.Body size="xs" color={Colors.gray["600"]}>
          {intl.formatMessage({
            defaultMessage: "Don’t have a Gemini account?",
          })}
        </Text.Body>
      )}

      <Button.Secondary
        size="sm"
        data-testid={testIds.link.goToRegister}
        href={formattedRegisterUrl}
        onClick={trackStartRegistration}
        cta={intl.formatMessage({
          defaultMessage: "Create a new account",
        })}
      />
    </Fragment>
  );
}
