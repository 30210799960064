import { forwardRef, useState } from "react";
import { useTheme } from "@emotion/react";
import { IconCopy } from "@hubble/icons";
import { Button, Flex, HubbleCard, HubbleListItem, List, Text, Tooltip } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";
export interface FundInstructionItem {
  title: string;
  values: string[];
  copyValue?: string;
  testId?: string;
}

export interface Footnote {
  text: string;
  testId?: string;
}

export interface InstructionsCardProps {
  title: string;
  items: FundInstructionItem[];
  footNotes?: Footnote[];
}

export const InstructionsCard = forwardRef<HTMLDivElement, InstructionsCardProps>(
  ({ title, items, footNotes = [] }, ref) => {
    const { intl } = useIntl();
    const { colorScheme } = useTheme();
    const [copied, setCopied] = useState(false);
    const onCopy = (copyValue: string) => {
      setCopied(true);
      navigator.clipboard.writeText(copyValue);
    };

    return (
      <HubbleCard ref={ref} density="md" variant="filled">
        <HubbleListItem hasDivider={false} density="md" left={<Text.Heading size="xs">{title}</Text.Heading>} />
        <List mt={1}>
          {items.map(({ title, values, copyValue, testId }, i) => (
            <HubbleListItem
              density="md"
              key={title}
              hasDivider={i !== items.length - 1}
              left={
                <Flex.Column>
                  <Text.Body data-testid={testId} color={colorScheme.content.secondary} size="sm">
                    {title}
                  </Text.Body>
                  <Flex.Column>
                    {values.map((val, i) => (
                      <Text.Body size="sm" key={`${i}-${val}`}>
                        {val}
                      </Text.Body>
                    ))}
                  </Flex.Column>
                </Flex.Column>
              }
              right={
                Boolean(copyValue) && (
                  <Tooltip
                    onVisibleChange={() => setCopied(false)}
                    overlay={
                      copied
                        ? intl.formatMessage({ defaultMessage: "✓ Copied" })
                        : intl.formatMessage({ defaultMessage: "Copy" })
                    }
                  >
                    <Button.Tertiary
                      size="sm"
                      aria-label={intl.formatMessage({ defaultMessage: "Copy {title} value" }, { title })}
                      icon={<IconCopy />}
                      onClick={() => onCopy(copyValue)}
                      data-testid={`copy-${title.toLowerCase().replace(" ", "-")}-value`}
                    />
                  </Tooltip>
                )
              }
            />
          ))}
          {footNotes.map(footNote => (
            <HubbleListItem
              key={footNote.text}
              density="md"
              left={
                <Text.Body data-testid={footNote.testId} size="xs" color={colorScheme.content.secondary}>
                  {footNote.text}
                </Text.Body>
              }
              hasDivider={false}
            />
          ))}
        </List>
      </HubbleCard>
    );
  }
);
