import { useAsyncFn } from "react-use";
import { EVENTS } from "@gemini-ui/analytics";
import { applicationApi } from "@gemini-ui/client/api";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { XStateSend } from "@gemini-ui/pages/Credit/CreditApplication/constants";
import { ClientState } from "@gemini-ui/pages/Credit/CreditApplication/machines/creditAppMachine";
import { trackAnalyticsEvent } from "@gemini-ui/pages/Credit/CreditApplication/tracking/analytics";
import { getError } from "@gemini-ui/utils/error";

export const useCheckEmail = () => {
  const { showAlert } = useAlert();

  return useAsyncFn(
    async (send: XStateSend, token: string, setVerificationAttempts: React.Dispatch<React.SetStateAction<number>>) => {
      try {
        await applicationApi.checkEmailVerificationTokenPrefill({ token });
        trackAnalyticsEvent({ trackingEvent: EVENTS.CC_CREDIT_NEW_USER_EMAIL.name });

        send(ClientState.SMS_VERIFY);
      } catch (error) {
        setVerificationAttempts(attempts => attempts + 1);
        showAlert({ type: AlertTypes.ERROR, message: getError(error) });
      }
    }
  );
};
