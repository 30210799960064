import { cloneElement, Fragment, ReactNode, useMemo, useState } from "react";
import { useTheme } from "@emotion/react";
import * as Sentry from "@sentry/browser";
import { useEffectOnce, useMedia } from "react-use";
import { isFiatCurrency } from "@gemini-common/scripts/constants/currencies";
import { track, trackBingEvent, trackFacebookPixelEvent, trackTikTokEvent } from "@gemini-ui/analytics";
import {
  BingEvents,
  BingVariableRevenueProperty,
  EVENTS,
  FacebookEvents,
  SNAPCHAT_PROPERTIES,
  TikTokEvents,
} from "@gemini-ui/analytics/constants/events";
import { snapchatTrack, snapEvents } from "@gemini-ui/analytics/snapchatTracking";
import { useAlert } from "@gemini-ui/components/GlobalAlert/AlertProvider";
import { AlertTypes } from "@gemini-ui/components/GlobalAlert/constants";
import { Money } from "@gemini-ui/components/Money";
import { GrowProviderType } from "@gemini-ui/constants/earn";
import { usePageRefresh } from "@gemini-ui/contexts";
import { Button, Card, Colors, Flex, List, ListItem, Spacing, Text } from "@gemini-ui/design-system";
import LinkText from "@gemini-ui/design-system/Text/LinkText";
import { buildTransferSummaryViews, renderMap } from "@gemini-ui/pages/Earn/Deposit/ReviewDeposit/renderer";
import { ConfirmButton } from "@gemini-ui/pages/Earn/Deposit/ReviewDeposit/styles";
import { DepositModalView, TransferQuote } from "@gemini-ui/pages/Earn/Deposit/types";
import { GEMINI_STAKING_AGREEMENT_URL, getMaxAccrualText } from "@gemini-ui/pages/Earn/Deposit/utils";
import { useGrowBuy } from "@gemini-ui/pages/Earn/GrowBuy/context";
import { GrowBuyDepositType } from "@gemini-ui/pages/Earn/GrowBuy/context/types";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { getQuoteError } from "@gemini-ui/pages/RetailTrade/AssetDetail/BuySell/utils";
import { StakingDepositMethod } from "@gemini-ui/services/staking/constants";
import { useStakingDeposit } from "@gemini-ui/services/staking/stakingDeposit";
import { useStakingQuote } from "@gemini-ui/services/staking/stakingQuote";
import { BREAKPOINTS } from "@gemini-ui/utils/breakpoints";
import { getError } from "@gemini-ui/utils/error";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

export enum PrimaryCurrencyDisplay {
  FIAT = "fiat",
  CRYPTO = "crypto",
}

export interface Props {
  /**
   * User may have entered the transfer amount in fiat or crypto in a preview step.
   * e.g. $100 instead of 0.0038582 ETH or 1 ETH instead of $1,204.62
   * This prop will determine which notation appears prioritized in the UI.
   */
  userPreferredCurrencyDisplay?: PrimaryCurrencyDisplay;
}

export const ReviewDeposit = (props: Props) => {
  const { userPreferredCurrencyDisplay = PrimaryCurrencyDisplay.CRYPTO } = props;

  const { intl } = useIntl();
  const { colorScheme } = useTheme();
  const { showAlert } = useAlert();

  const {
    currency,
    provider,
    depositStatus,
    setQuote: setGrowBuyContextQuote,
    setDepositError,
    setModalView,
  } = useGrowBuy();
  const { amount, source } = depositStatus as GrowBuyDepositType<TransferQuote>;

  const { requestRefresh } = usePageRefresh();

  const { data: transferQuote, loading, error, fetchQuote } = useStakingQuote<TransferQuote>();
  const { submitDeposit } = useStakingDeposit();

  useEffectOnce(() => {
    const getQuote = async () => {
      try {
        const quote = await fetchQuote(StakingDepositMethod.TRANSFER, {
          providerId: provider?.id,
          currency,
          amount,
        });

        // TODO: The `Success` modal will pull the amounts from the `depositStatus` context, so we need to update that.
        // This should be refactored to elimate this unnecessary step.
        setGrowBuyContextQuote(quote);
      } catch (e) {
        const quoteError = getQuoteError(error, intl);
        setDepositError(quoteError);
        showAlert({ type: AlertTypes.ERROR, message: quoteError, timeout: 5000 });
        setModalView(DepositModalView.PLACE_DEPOSIT);
      }
    };

    getQuote();
  });

  const isMobile = useMedia(BREAKPOINTS.mobileDown);
  const [submitting, setSubmitting] = useState(false);

  const isPrivateStaking = provider?.providerType === GrowProviderType.PRIVATE_STAKING;

  const accrualText = useMemo(
    () => getMaxAccrualText(provider?.maxAccrueDelay, intl),
    [provider?.maxAccrueDelay, intl]
  );

  const handleSubmit = async () => {
    setSubmitting(true);
    try {
      const trackCurrency = transferQuote?.notionalAmount?.currency;
      const trackAmount = Number(transferQuote?.notionalAmount?.value);
      const strTrackAmount = parseFloat(trackAmount.toString()).toFixed(2);

      track(EVENTS.STAKE_DEPOSIT.name, {
        [EVENTS.STAKE_DEPOSIT.properties.CURRENCY]: currency,
        [EVENTS.STAKE_DEPOSIT.properties.PROVIDER_TYPE]: provider?.providerType,
        [EVENTS.STAKE_DEPOSIT.properties.AMOUNT]: strTrackAmount,
        [EVENTS.STAKE_DEPOSIT.properties.FUNDING_SOURCE]: source,
      });
      snapchatTrack(snapEvents.addCart, {
        [SNAPCHAT_PROPERTIES.CURRENCY]: trackCurrency,
        [SNAPCHAT_PROPERTIES.PRICE]: trackAmount,
      });
      trackTikTokEvent(TikTokEvents.EarnDeposit);
      trackFacebookPixelEvent(FacebookEvents.AddToCart, { currency: trackCurrency, value: amount });

      // Bing UETG tracking will only accept ISO currency codes
      if (isFiatCurrency(trackCurrency))
        trackBingEvent(BingEvents.EarnDeposit, {
          [BingVariableRevenueProperty.RevenueValue]: strTrackAmount,
          [BingVariableRevenueProperty.Currency]: trackCurrency,
        });

      await submitDeposit(StakingDepositMethod.TRANSFER, {
        currency,
        amount,
        providerId: provider?.id,
      });
      requestRefresh();
      setModalView(DepositModalView.SUCCESS);
    } catch (e) {
      Sentry.captureException(e);
      showAlert({ type: AlertTypes.ERROR, message: getError(e) });
      setSubmitting(false);
    }
  };

  const rows = useMemo(() => {
    const views = buildTransferSummaryViews({
      intl,
      provider,
      transferQuote,
    });

    return renderMap[provider?.providerType]?.map(key => views?.[key]) ?? [];
  }, [provider, transferQuote, intl]);

  const transferAmountTitleView = useMemo(() => {
    const isCryptoPrimaryDisplay = userPreferredCurrencyDisplay === PrimaryCurrencyDisplay.CRYPTO;
    const [transferPrimaryCurrency, transferSecondaryCurrency] = isCryptoPrimaryDisplay
      ? [transferQuote?.amount, transferQuote?.notionalAmount]
      : [transferQuote?.notionalAmount, transferQuote?.amount];

    return (
      <Fragment>
        <Text.Display size="xs" numeric>
          <Money
            data-testid={testIds.deposit.reviewDeposit.titlePrimary}
            hideTrailingSign={!isCryptoPrimaryDisplay}
            {...transferPrimaryCurrency}
          />
        </Text.Display>
        <Text.Body size="sm" color={colorScheme.content.secondary} bold>
          <Money
            data-testid={testIds.deposit.reviewDeposit.titleSecondary}
            hideTrailingSign={isCryptoPrimaryDisplay}
            {...transferSecondaryCurrency}
          />
        </Text.Body>
      </Fragment>
    );
  }, [
    transferQuote?.amount,
    transferQuote?.notionalAmount,
    userPreferredCurrencyDisplay,
    colorScheme.content.secondary,
  ]);

  const validatorsAmountTitleView = useMemo(() => {
    return (
      <Fragment>
        <Text.Display data-testid={testIds.deposit.reviewDeposit.titlePrimary} size="xs" numeric center>
          {intl.formatMessage(
            defineMessage({
              defaultMessage: "{numberOfValidators} {numberOfValidators, plural, one {validator} other {validators}}",
            }),
            { numberOfValidators: transferQuote?.validatorsResponse?.numberOfValidators }
          )}
        </Text.Display>
        <Text.Body size="sm" color={colorScheme.content.secondary} bold>
          <Money data-testid={testIds.deposit.reviewDeposit.titleSecondary} {...transferQuote?.amount} />
        </Text.Body>
      </Fragment>
    );
  }, [
    transferQuote?.validatorsResponse?.numberOfValidators,
    transferQuote?.amount,
    colorScheme.content.secondary,
    intl,
  ]);

  return (
    <Fragment>
      <Flex flexDirection="column" gap={Spacing.scale[2]}>
        {Boolean(transferQuote) && (
          <Flex flexDirection="column" align="center" gap={Spacing.scale[1]}>
            {isPrivateStaking ? validatorsAmountTitleView : transferAmountTitleView}
          </Flex>
        )}

        <Card variant="filled" padding="none">
          <List>
            {rows.map((row, idx) => {
              // It's assumed that the row is a `ListItem`, but there may be cases where we want
              // to have custom list elements - like in breaking down tiered rates. In this scenario,
              // we can use the `override` prop to pass in a custom element.
              if (row?.override) return cloneElement(row?.override, { key: idx });
              return <ListItem key={idx} size="dense" padding="sm" alignItems="center" hasDivider={true} {...row} />;
            })}

            <ListItem size="dense" padding="sm">
              <Text.Legal data-testid={testIds.deposit.reviewDeposit.disclaimer}>
                {intl.formatMessage(
                  defineMessage({
                    defaultMessage:
                      'Gemini does not guarantee that you will receive any staking rewards.<br></br><br></br>By {isMobile, select, true {tapping} other {clicking}} "confirm," you are agreeing to stake your crypto according to the terms of the Gemini Trust Company, LLC Staking Agreement. Please note that the time to start receiving staking rewards varies by network, but our customers typically start earning rewards {accrualText} of staking. <Link>Read more</Link>',
                  }),
                  {
                    br: () => <br />,
                    Link: (str: ReactNode) => (
                      <LinkText
                        href={GEMINI_STAKING_AGREEMENT_URL}
                        target="_blank"
                        rel="noreferrer"
                        color={Colors.gray[500]}
                      >
                        {str}
                      </LinkText>
                    ),
                    isMobile,
                    accrualText,
                  }
                )}
              </Text.Legal>
            </ListItem>
          </List>
        </Card>
      </Flex>

      <Button.Group type="action">
        <ConfirmButton
          data-testid={testIds.deposit.reviewDeposit.completeButton}
          onClick={handleSubmit}
          loading={submitting || loading}
          disabled={loading}
          size="lg"
          cta={
            !loading && intl.formatMessage({ defaultMessage: "Confirm order", description: "Complete this deposit" })
          }
        />
      </Button.Group>
    </Fragment>
  );
};
