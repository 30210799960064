import { Fragment, useMemo } from "react";
import { useTheme } from "@emotion/react";
import { IconCheck } from "@hubble/icons";
import { Money } from "@gemini-ui/components/Money";
import { usePageData } from "@gemini-ui/contexts";
import { Button, Card, Flex, IconBadge, List, Spacing, Text } from "@gemini-ui/design-system";
import SummaryLineItem from "@gemini-ui/pages/Earn/Deposit/ReviewBuyDeposit/SummaryLineItem";
import { calculateValue } from "@gemini-ui/pages/Earn/Deposit/utils";
import { PlaceRedeemInfo } from "@gemini-ui/pages/Earn/Redeem/types";
import { testIds } from "@gemini-ui/pages/Earn/testIds";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface Props extends Pick<PlaceRedeemInfo, "amount" | "currency" | "redemptionStatus"> {
  lastTradePrice: string;
  onDone?: () => void;
}

export const Success = ({ lastTradePrice, amount, currency, onDone }: Props) => {
  const { intl } = useIntl();
  const { colorScheme } = useTheme();
  const {
    templateProps: {
      account: { defaultFiat },
    },
  } = usePageData();

  const notionalAmount = useMemo(
    () =>
      calculateValue({
        value: amount,
        lastTradePrice,
      }),
    [amount, lastTradePrice]
  );

  return (
    <Fragment>
      <Flex alignItems="center" justifyContent="center" flexDirection="column" mb={2} gap={Spacing.scale[0.5]}>
        <Flex alignItems="center" justifyContent="center" mb={1}>
          <IconBadge
            color={colorScheme.status.default.content.positive}
            backgroundColor={colorScheme.status.default.background.positive}
            size="xl"
            icon={<IconCheck size="xl" />}
          />
        </Flex>
        <Text.Heading size="md">
          {intl.formatMessage({ defaultMessage: "You unstaked {currency}." }, { currency })}
        </Text.Heading>
        <Text.Body size="sm" center color={colorScheme.content.secondary} data-testid={testIds.redeem.success.message}>
          {intl.formatMessage(
            defineMessage({
              defaultMessage: "Your {currency} will be moved from your staking balance to your trading balance.",
            }),
            { currency }
          )}
        </Text.Body>
      </Flex>
      <Card variant="filled" padding="none">
        <List>
          <SummaryLineItem
            label={intl.formatMessage({ defaultMessage: "Quantity" })}
            text={<Money currency={currency} value={amount}></Money>}
            data-testid={testIds.redeem.success.assetAmount}
          />
          <SummaryLineItem
            label={intl.formatMessage({ defaultMessage: "Total" })}
            text={<Money currency={defaultFiat} value={notionalAmount} hideTrailingSign />}
            data-testid={testIds.redeem.success.fiatAmount}
          />
        </List>
      </Card>
      <Button.Group type="stacked">
        <Button.Primary
          size="lg"
          mb={1}
          data-testid={testIds.redeem.success.doneButton}
          onClick={() => {
            onDone?.();
          }}
          cta={intl.formatMessage({ defaultMessage: "Done" })}
        />
        <Button.Secondary
          size="lg"
          mb={1}
          data-testid={testIds.redeem.success.portfolioButton}
          onClick={() => window.location.assign(jsRoutes.controllers.dashboard.DashboardController.portfolio().url)}
          cta={intl.formatMessage({ defaultMessage: "Go to Portfolio" })}
        />
      </Button.Group>
    </Fragment>
  );
};
