import { Fragment, ReactNode, useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { CurrencyShortNameFiat, currencyToLower, isCurrency } from "@gemini-common/scripts/constants/currencies";
import { Articles, HelpCenterLink } from "@gemini-ui/components/HelpCenterLink";
import { RegulatoryFooter } from "@gemini-ui/components/RegulatoryFooter";
import { TEST_IDS, WireTransferInfoAPIPayload } from "@gemini-ui/components/WireDepositDetails/constants";
import { InstructionsCard } from "@gemini-ui/components/WireDepositDetails/InstructionsCard";
import {
  beneficiaryToFundInstructionItems,
  depositInformationToFundInstructionItems,
  domesticBeneficiaryToFundInstructionItems,
  domesticReceivingBankToFundInstructionItems,
  formatTransferMechanismText,
  intermediaryToFundInstructionItems,
  receivingBankToFundInstructionItems,
  referenceToFundInstructionItems,
} from "@gemini-ui/components/WireDepositDetails/utils";
import { usePageData } from "@gemini-ui/contexts";
import { EmptyState, SectionMessage, Spacer, Text } from "@gemini-ui/design-system";
import ConfirmEmailLockoutV2 from "@gemini-ui/pages/transfers/components/ConfirmEmailLockoutV2";
import VerificationLockoutV2 from "@gemini-ui/pages/transfers/components/VerificationLockoutV2";
import axios from "@gemini-ui/services/axios";
import { HEADERS } from "@gemini-ui/services/constants";
import { defineMessage, useIntl } from "@gemini-ui/utils/intl";

interface WireDepositDetailsProps extends WireTransferInfoAPIPayload {
  currency: CurrencyShortNameFiat;
  isXfers?: boolean;
  isRtp?: boolean;
  sendEmail?: boolean;
  subaccountHashid?: string;
}

interface InternationalDepositDetailsProps {
  internationalBankInfo: WireTransferInfoAPIPayload["internationalBankInfo"];
  isSGD: boolean;
  isRtp: boolean;
  currency: CurrencyShortNameFiat;
}

const InternationalDepositDetails = ({
  internationalBankInfo,
  isSGD,
  currency,
  isRtp,
}: InternationalDepositDetailsProps) => {
  const { intl } = useIntl();
  const isSGDCurrency = isCurrency.SGD(currency);
  return (
    <Fragment>
      {/* Corresponding Bank Section */}
      {Boolean(internationalBankInfo.intermediary) && (
        <Spacer mt={2}>
          <InstructionsCard
            title={intl.formatMessage(
              { defaultMessage: "{bankTitle} bank" },
              { bankTitle: internationalBankInfo.intermediary.title }
            )}
            items={intermediaryToFundInstructionItems(internationalBankInfo.intermediary, intl)}
          />
        </Spacer>
      )}

      {/* Receiving Bank Section */}
      {Boolean(internationalBankInfo.receivingBank) && (
        <Spacer mt={2}>
          <InstructionsCard
            title={intl.formatMessage({ defaultMessage: "Receiving bank" })}
            items={receivingBankToFundInstructionItems(
              internationalBankInfo.receivingBank,
              internationalBankInfo.beneficiary,
              intl
            )}
          />
        </Spacer>
      )}

      {/* Beneficiary/Recipient Section */}
      {Boolean(internationalBankInfo.beneficiary) && (
        <Spacer mt={2}>
          <InstructionsCard
            title={intl.formatMessage({ defaultMessage: "Beneficiary/Recipient" })}
            items={beneficiaryToFundInstructionItems(internationalBankInfo.beneficiary, isSGDCurrency, intl)}
            footNotes={
              isSGD && !isRtp
                ? [
                    {
                      text: intl.formatMessage({
                        defaultMessage: "Gemini works with Xfers Pte Ltd to facilitate your SGD transfers.",
                      }),
                    },
                  ]
                : []
            }
          />
        </Spacer>
      )}
    </Fragment>
  );
};

interface DomesticDepositDetailsProps {
  domesticBankInfo: WireTransferInfoAPIPayload["domesticBankInfo"];
}

const DomesticDepositDetails = ({ domesticBankInfo }: DomesticDepositDetailsProps) => {
  const { receivingBank, beneficiary } = domesticBankInfo;
  const { intl } = useIntl();
  const routingNumber =
    typeof receivingBank.routingNumber === "string" ? receivingBank.routingNumber : receivingBank.routingNumber.id;

  return (
    <Fragment>
      {/* Receiving Bank Section */}
      {Boolean(receivingBank) && (
        <Spacer mt={2}>
          <InstructionsCard
            title={intl.formatMessage({ defaultMessage: "Receiving bank" })}
            items={domesticReceivingBankToFundInstructionItems(receivingBank, routingNumber, intl)}
          />
        </Spacer>
      )}

      {/* Beneficiary/Recipient Section */}
      {Boolean(beneficiary) && (
        <Spacer mt={2}>
          <InstructionsCard
            title={intl.formatMessage({ defaultMessage: "Beneficiary/Recipient" })}
            items={domesticBeneficiaryToFundInstructionItems(beneficiary, intl)}
          />
        </Spacer>
      )}
    </Fragment>
  );
};

export const WireDepositDetails = ({
  currency,
  isXfers,
  isRtp,
  isInternational,
  domesticBankInfo,
  internationalBankInfo,
  reference,
  subaccountHashid,
  sendEmail = false,
  vaStatus,
}: WireDepositDetailsProps) => {
  const { intl } = useIntl();
  const {
    templateProps: {
      user: { emailConfirmed, isFullyVerified, isBasicPlusTier },
      account: { geminiEntity },
    },
  } = usePageData();
  const isSGD = isRtp || isXfers;
  const isGBPorEUR = ["GBP", "EUR"].includes(currency);
  const transferMechanismSupportedText = formatTransferMechanismText(currency, isSGD, geminiEntity, intl);
  const xfersLimit = "200,000";

  useEffect(() => {
    const sendWireInstructionsEmail = async () => {
      const request = jsRoutes.controllers.transfer.DepositWireController.sendWireDepositInstructionsEmail(
        currencyToLower(currency)
      ).url;
      const config = subaccountHashid
        ? {
            headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid },
          }
        : undefined;
      try {
        await axios.post(request, undefined, config);
      } catch (e) {
        const sentryErrorMessage = `Failed to send wire instructions email for ${subaccountHashid} with ${e.toString()}`;
        Sentry.captureMessage(sentryErrorMessage, "error");
      }
    };
    if (sendEmail) {
      sendWireInstructionsEmail();
    }
  }, [sendEmail, subaccountHashid, currency]);
  // basic+ and fully verified can deposit  fiat + trade
  const isUserVerificationLocked = !isFullyVerified && !isBasicPlusTier;
  return (
    <Fragment>
      {!emailConfirmed && <ConfirmEmailLockoutV2 intl={intl} />}
      {emailConfirmed && isUserVerificationLocked && <VerificationLockoutV2 intl={intl} />}
      {Boolean(vaStatus) && vaStatus === "Pending" ? (
        <EmptyState
          label={intl.formatMessage({
            defaultMessage: "Your deposit instructions are being prepared.",
          })}
          description={intl.formatMessage({
            defaultMessage: "It usually takes up to 24 hours. We will notify you as soon as it's ready.",
          })}
          status="informational"
        />
      ) : (
        <Fragment>
          <Text.Body size="md">
            {!isRtp
              ? intl.formatMessage(
                  defineMessage({
                    defaultMessage:
                      "Issue a {isSGD, select, true {FAST} other {{isGBPorEUR, select, true {bank} other {wire}}}} transfer from your bank account to your Gemini account using instructions below.",
                  }),
                  {
                    isSGD,
                    isGBPorEUR,
                  }
                )
              : intl.formatMessage(
                  defineMessage({
                    defaultMessage:
                      "Fund your Gemini account through FAST, and your bank account will be verified for withdrawals. For additional questions, <helplink>visit our FAQ.</helplink>",
                  }),
                  {
                    helplink: (str: ReactNode) => (
                      <HelpCenterLink article={Articles.RTP_INSTRUCTIONS}>{str}</HelpCenterLink>
                    ),
                  }
                )}
          </Text.Body>
          {isSGD && !isRtp && (
            <Text.Body size="md" bold mt={1.5}>
              {intl.formatMessage(
                defineMessage({
                  defaultMessage: "Please note there is a {xfersLimit} SGD limit per deposit.",
                }),
                {
                  xfersLimit,
                }
              )}
            </Text.Body>
          )}
          {!isRtp && (
            <Text.Body size="md" mt={1}>
              {intl.formatMessage(
                defineMessage({
                  defaultMessage:
                    "If you have questions about how to make a {isSGD, select, true {deposit} other {transfer}}, contact your bank for assistance or <helplink>visit our FAQ.</helplink>",
                }),
                {
                  isSGD,
                  helplink: (str: ReactNode) => (
                    <HelpCenterLink article={isSGD ? Articles.XFERS_INSTRUCTIONS : Articles.WIRE_INSTRUCTIONS}>
                      {str}
                    </HelpCenterLink>
                  ),
                }
              )}
            </Text.Body>
          )}

          <SectionMessage statusType="info" mt={3}>
            {isRtp
              ? intl.formatMessage({
                  defaultMessage:
                    "The name on your bank account must match the name on your Gemini account. Otherwise, your transfer may be delayed or returned.",
                })
              : intl.formatMessage({
                  defaultMessage:
                    "If the name on your bank account does not match the name on your Gemini account (including joint or business accounts) your transfer may be delayed or returned. Make sure the bank account is already added to your Gemini account.",
                })}
          </SectionMessage>
          <Spacer mt={2}>
            <InstructionsCard
              title={intl.formatMessage({ defaultMessage: "Deposit information" })}
              items={depositInformationToFundInstructionItems(currency, transferMechanismSupportedText, intl)}
              footNotes={[
                ...(isSGD
                  ? [
                      {
                        text: intl.formatMessage({
                          defaultMessage:
                            "We do not support transfers from third party platforms like Grab, Aspire, Revolut, FOMO pay etc.",
                        }),
                      },
                    ]
                  : [
                      {
                        text: intl.formatMessage({
                          defaultMessage: "Other types of transfers may be delayed or returned.",
                        }),
                        testId: TEST_IDS.wireDepositInfoText,
                      },
                    ]),
              ]}
            />
          </Spacer>

          {Boolean(reference) && (
            <Spacer mt={2}>
              <InstructionsCard
                title={intl.formatMessage({ defaultMessage: "Reference code" })}
                items={referenceToFundInstructionItems(reference, intl)}
                footNotes={[
                  {
                    text: intl.formatMessage({
                      defaultMessage:
                        "Include your account reference code in the memo, instruction, or description field. This code is used to match deposits to your Gemini account. Without it, deposits may be delayed or returned.",
                    }),
                  },
                ]}
              />
            </Spacer>
          )}

          {isInternational || !domesticBankInfo ? (
            <InternationalDepositDetails
              internationalBankInfo={internationalBankInfo}
              isSGD={isSGD}
              currency={currency}
              isRtp={isRtp}
            />
          ) : (
            <DomesticDepositDetails domesticBankInfo={domesticBankInfo} />
          )}

          <RegulatoryFooter isFiatTransaction={true} />
        </Fragment>
      )}
    </Fragment>
  );
};
