import { EVENTS, track } from "@gemini-ui/analytics";
import { ApplicationStatusStateStateEnum, Currency, JsonInterestRatesResponse, Offer } from "@gemini-ui/client/credit";
import { useCardContext } from "@gemini-ui/pages/Credit/CreditApplication/CardContext";
import { CREDIT_MAX, CREDIT_MIN, UserData } from "@gemini-ui/pages/Credit/CreditApplication/constants";
import { ClientState, ClientViews } from "@gemini-ui/pages/Credit/CreditApplication/machines/creditAppMachine";
import { formatCurrency } from "@gemini-ui/pages/Credit/CreditApplication/utils";

const stateChangeEvents: Partial<Record<ClientViews, string>> = {
  [ClientState.NEW_APPLICATION]: EVENTS.CC_START_APPLICATION.name,
  [ClientState.SMS_VERIFY]: EVENTS.CC_PHONE_CONFIRMATION.name,
  [ClientState.EMAIL_VERIFY]: EVENTS.CC_EMAIL_CONFIRMATION.name,
  [ClientState.UPLOAD_DOCUMENTS]: EVENTS.CC_ONFIDO.name,
  [ClientState.ENTER_INCOME]: EVENTS.CC_INCOME.name,
  [ClientState.CARD_SELECTION]: EVENTS.CC_CUSTOMIZE_CARD.name,
  [ClientState.RECENT_DENIED]: EVENTS.CC_RECENT_APP_DENIED.name,
  [ClientState.INELIGIBLE_TO_APPLY]: EVENTS.CC_INELIGIBLE.name,
  [ClientState.GENERIC_ERROR]: EVENTS.CC_SERVICE_FAILURE.name,
  [ApplicationStatusStateStateEnum.SoftPullDenied]: EVENTS.CC_SOFT_PULL_DENIED.name,
  [ApplicationStatusStateStateEnum.BiometricDenied]: EVENTS.CC_BIOMETRIC_DENIED.name,
  [ApplicationStatusStateStateEnum.KycMismatch]: EVENTS.CC_KYC_DENIED.name,
  [ApplicationStatusStateStateEnum.KycFailed]: EVENTS.CC_KYC_DENIED.name,
  [ApplicationStatusStateStateEnum.SoftPullDeceased]: EVENTS.CC_DECEASED.name,
  [ApplicationStatusStateStateEnum.HardPullDeceased]: EVENTS.CC_DECEASED.name,
  [ApplicationStatusStateStateEnum.SoftPullFrozen]: EVENTS.CC_SOFT_PULL_FROZEN.name,
  [ApplicationStatusStateStateEnum.HardPullFrozen]: EVENTS.CC_HARD_PULL_FROZEN.name,
  [ApplicationStatusStateStateEnum.KycServiceFailure]: EVENTS.CC_SERVICE_FAILURE.name,
  [ApplicationStatusStateStateEnum.SoftPullServiceFailure]: EVENTS.CC_SERVICE_FAILURE.name,
  [ApplicationStatusStateStateEnum.HardPullServiceFailure]: EVENTS.CC_SERVICE_FAILURE.name,
  [ApplicationStatusStateStateEnum.KycManualReview]: EVENTS.CC_MANUAL_REVIEW.name,
  [ApplicationStatusStateStateEnum.BiometricManual]: EVENTS.CC_MANUAL_REVIEW.name,
};

export const useMixpanelEvents = (user: UserData) => {
  const { cardColor } = useCardContext();
  const fixedProperties = {
    isNew: !user?.isLoggedIn,
    cardSelection: cardColor,
  };

  return {
    trackStateChange: (currentState: ClientViews) => {
      const currentStateName = stateChangeEvents[currentState];
      const existsInEvents = Object.values(EVENTS).some(event => {
        const hasNameProperty = event && typeof event === "object" && "name" in event;
        if (hasNameProperty) return event.name === currentStateName;
      });

      if (existsInEvents) {
        track(currentStateName, fixedProperties, { braze: true });
      }
    },
    trackResendEmailCode: () => {
      track(EVENTS.CC_RESEND_EMAIL_CODE.name, fixedProperties, { braze: true });
    },
    trackSoftPullApproved: (rates: JsonInterestRatesResponse) => {
      const { name, properties } = EVENTS.CC_SOFT_PULL_APPROVED;

      track(
        name,
        {
          ...fixedProperties,
          [properties.CREDIT_LIMIT_MIN]: formatCurrency(CREDIT_MIN),
          [properties.CREDIT_LIMIT_MAX]: formatCurrency(CREDIT_MAX),
          [properties.APR_MIN]: rates.primeRate + rates.minimumApr,
          [properties.APR_MAX]: rates.primeRate + rates.maximumApr,
        },
        { braze: true }
      );
    },
    trackTermsReview: (schumerBoxHTML: string) => {
      const { name, properties } = EVENTS.CC_TERMS_REVIEW;
      const rates = schumerBoxHTML?.match(/[\d.]+% ?/g);

      track(
        name,
        {
          ...fixedProperties,
          [properties.APR_MIN]: rates?.[0],
          [properties.APR_MAX]: rates?.[1],
        },
        { braze: true }
      );
    },
    trackTermsAgree: (schumerBoxHTML: string) => {
      const { name, properties } = EVENTS.CC_TERMS_AGREE;
      const rates = schumerBoxHTML?.match(/[\d.]+% ?/g);

      track(
        name,
        {
          ...fixedProperties,
          [properties.APR_MIN]: rates?.[0],
          [properties.APR_MAX]: rates?.[1],
        },
        { braze: true }
      );
    },
    trackHardPullApproved: (offer: Offer) => {
      const { name, properties } = EVENTS.CC_HARD_PULL_APPROVED;

      track(
        name,
        {
          ...fixedProperties,
          [properties.APR]: `${offer?.apr}%`,
          [properties.CREDIT_LIMIT]: formatCurrency(offer?.creditLimit),
          [properties.TEMPORARY_CREDIT_LIMIT]: formatCurrency(offer?.temporaryCreditLimit),
        },
        { braze: true }
      );
    },
    trackSearchCrypto: (searchTerm: string) => {
      const { name, properties } = EVENTS.CC_SEARCH_CRYPTO;

      track(
        name,
        {
          ...fixedProperties,
          [properties.SEARCH_TERM]: searchTerm,
        },
        { braze: true }
      );
    },
    trackChooseCrypto: (reward: Currency) => {
      const { name, properties } = EVENTS.CC_CHOOSE_CRYPTO;

      track(
        name,
        {
          ...fixedProperties,
          [properties.CRYPTO_REWARD]: reward,
        },
        { braze: true }
      );
    },
  };
};
