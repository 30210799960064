import { SupportedBank } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/constants";
import { IntlShape } from "@gemini-ui/utils/intl";

export enum InternationalPrefix {
  Singapore = 65,
}

export const mapErrorToForm = formErrors => {
  const mappedFormErrors = formErrors as {
    phoneNumber?: string[];
    otp?: string[];
    intlPrefix?: string[];
  };

  if (formErrors["phone.number"]) {
    mappedFormErrors.phoneNumber = formErrors["phone.number"];
  }

  if (formErrors["phone.otp"]) {
    mappedFormErrors.otp = formErrors["phone.otp"];
  }

  if (formErrors["phone.intlPrefix"]) {
    mappedFormErrors.intlPrefix = formErrors["phone.intlPrefix"];
  }

  return mappedFormErrors;
};

export const validateAccountNumber = (value: string, bank: SupportedBank | undefined, intl: IntlShape) => {
  if (!value) {
    return intl.formatMessage({ defaultMessage: "Please enter the bank account number." });
  }
  const sanitizedValue = value.replace(" ", "");
  if (bank && (bank.maxLength < sanitizedValue.length || bank.minLength > sanitizedValue.length)) {
    return `Bank account number must be ${bank.minLength} to ${bank.maxLength} characters.`;
  }
};

export const createDropDownValue = (bank: SupportedBank) => {
  if (!bank) {
    return null;
  }
  const { bankName, bankAbbreviation } = bank;
  return {
    value: bankAbbreviation,
    label: `${bankName} (${bankAbbreviation})`,
  };
};

export const getInstructionsRoute = (currency: string) =>
  `/payments/deposit/instructions/RTP?currency=${currency}&sendEmail=true`;
