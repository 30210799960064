import { CardApprovalState, PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import {
  BankAccountTransferLevel,
  BankAccountType,
  BankSettingsInitialData,
  BankSettingsPageDataType,
  BankType,
  BcoloAccountType,
  DebitCardType,
  PayPalAccountType,
  RtpType,
  XfersAccountType,
} from "@gemini-ui/transformers/PaymentMethods/constants";
import { extractPaymentMethodMetaDataFromName } from "@gemini-ui/transformers/PaymentMethods/utils";

export const transformBanks = (banks: BankSettingsInitialData["banks"]): Array<BankType | RtpType> => {
  return banks.map(
    ({ uuid, name, currency, lastUpdated, achLevel, wireLevel, supportsPlaidPaymentInitiation, rtpLevel }) => {
      const { institutionName, lastFour, accountType, rawName } = extractPaymentMethodMetaDataFromName(name);
      return {
        id: uuid,
        displayName: rawName,
        currencies: [currency],
        createdAt: lastUpdated,
        lastFour,
        institutionName,
        paymentMethodType:
          !Boolean(rtpLevel) || rtpLevel === BankAccountTransferLevel.NoTransfers
            ? PaymentMethodType.BANK
            : PaymentMethodType.RTP,
        achLevel: BankAccountTransferLevel[achLevel],
        wireLevel: BankAccountTransferLevel[wireLevel],
        accountType,
        isVerified: achLevel === "Approved" || wireLevel === "Approved" || rtpLevel === "Approved",
        supportsPlaidPaymentInitiation,
        rtpLevel: BankAccountTransferLevel[rtpLevel],
      };
    }
  );
};

export const transformDebitCard = ({
  uuid,
  createdAt,
  displayName,
  approvalState,
  currency,
  cardIssuer,
  ...restOfCardData
}: BankSettingsInitialData["cards"][0]): DebitCardType => {
  const { lastFour, rawName } = extractPaymentMethodMetaDataFromName(displayName);
  const isVerified = [
    CardApprovalState.Approved,
    CardApprovalState.MicroDepositApproved,
    CardApprovalState.BouncerApproved,
    CardApprovalState.StripeApproved,
    CardApprovalState["3DSRequired"],
  ].includes(approvalState);
  return {
    id: uuid,
    paymentMethodType: PaymentMethodType.DEBIT_CARD,
    lastFour,
    createdAt,
    displayName: rawName,
    isVerified,
    approvalState,
    currencies: [currency],
    cardIssuer: typeof cardIssuer === "string" ? cardIssuer : cardIssuer.type,
    ...restOfCardData,
  };
};

export const transformDebitCards = (cards: BankSettingsInitialData["cards"]): Array<DebitCardType> => {
  return cards.map(transformDebitCard);
};

export const transformBcoloAccounts = (
  bcolo: BankSettingsInitialData["bcolo"]["accounts"]
): Array<BcoloAccountType> => {
  return bcolo.map(({ uuid, name, type, lastFour, createdAt, currency, available }) => ({
    paymentMethodType: PaymentMethodType.BCOLO,
    id: uuid,
    accountType: BankAccountType[type],
    displayName: Boolean(name) ? name : `${type}${lastFour ? ` - ${lastFour}` : ""}`,
    institutionName: "Bancolombia",
    lastFour,
    createdAt,
    currencies: [currency],
    available,
    isVerified: true,
  }));
};

export const transformXferAccounts = (xfers: BankSettingsInitialData["xfers"]["accounts"]): Array<XfersAccountType> => {
  return xfers.map(({ uuid, currency, createdAt, name, xfersApproved, enabled }) => {
    const { lastFour, institutionName } = extractPaymentMethodMetaDataFromName(name);
    return {
      paymentMethodType: PaymentMethodType.XFERS,
      displayName: name,
      isVerified: xfersApproved,
      enabled,
      id: uuid,
      currencies: [currency],
      createdAt,
      institutionName,
      lastFour,
    };
  });
};

export const transformPayPalAccounts = (
  payPalAccounts: BankSettingsInitialData["payPal"]["accounts"]
): Array<PayPalAccountType> => {
  return payPalAccounts.map(({ uuid, currency, createdAt, displayName }) => {
    return {
      paymentMethodType: PaymentMethodType.PAYPAL,
      displayName,
      id: uuid,
      currencies: currency,
      createdAt,
      institutionName: "PayPal",
      isVerified: true,
    };
  });
};

export const transformPaymentMethods = (data: BankSettingsInitialData): BankSettingsPageDataType["paymentMethods"] => {
  const transformedBanks = transformBanks(data.banks);
  const transformedCards = transformDebitCards(data.cards);
  const transformedBcolo = transformBcoloAccounts(data.bcolo.accounts);
  const transformXfers = transformXferAccounts(data.xfers.accounts);
  const transformPayPal = transformPayPalAccounts(data.payPal.accounts);

  return [...transformedBanks, ...transformedCards, ...transformedBcolo, ...transformXfers, ...transformPayPal];
};
