import { CurrencyShortName } from "@gemini-common/scripts/constants/currencies";
import { PaymentMethodType } from "@gemini-ui/constants/paymentMethods";
import { TransferDestination, WhitelistAddress } from "@gemini-ui/pages/transfers/constants";
import {
  AttestationTypeRadioValues,
  DestinationTypeRadioValues,
  OutboundAttestation,
  RecipientTypeRadioValues,
} from "@gemini-ui/pages/transfers/Withdraw/TransferAttestation/constants";
import { DestinationItem } from "@gemini-ui/pages/transfers/Withdraw/WithdrawDestinationSelection/constants";
import { BankAccountTransferLevel, PaymentMethodDataType } from "@gemini-ui/transformers/PaymentMethods";

export const hasApprovedWithdrawablePaymentMethod = (paymentMethods: PaymentMethodDataType[]) =>
  paymentMethods.find(paymentMethod => {
    const { paymentMethodType } = paymentMethod;
    return (
      (paymentMethodType === PaymentMethodType.BANK &&
        (paymentMethod.achLevel === BankAccountTransferLevel.Approved ||
          paymentMethod.wireLevel === BankAccountTransferLevel.Approved ||
          paymentMethod.senLevel === BankAccountTransferLevel.Approved ||
          paymentMethod.blincLevel === BankAccountTransferLevel.Approved ||
          paymentMethod.rtpLevel === BankAccountTransferLevel.Approved)) ||
      (paymentMethodType === PaymentMethodType.XFERS && paymentMethod.isVerified) ||
      (paymentMethodType === PaymentMethodType.BCOLO && paymentMethod.available) ||
      paymentMethodType === PaymentMethodType.CBIT
    );
  });

export const getDestinationItems = (
  paymentMethods?: PaymentMethodDataType[],
  whitelist?: WhitelistAddress[]
): DestinationItem[] => {
  if (paymentMethods) {
    return paymentMethods.map(b => {
      const label = b.displayName;
      return { label, value: b.id };
    });
  }
  if (whitelist) {
    return whitelist.map(w => ({
      label: w.label || w.address.wrapped,
      value: w.address.wrapped,
      ...(w.memo && { memo: w.memo }),
    }));
  }
  return [];
};

// This should not be necessary once we switch to using a better dropdown that passes back the actual browser event
// and we can parse all the data we need out of that
export const getFormattedDestination = (
  newDestinationValue: DestinationItem,
  paymentMethods?: PaymentMethodDataType[],
  whitelist?: WhitelistAddress[]
): TransferDestination => {
  const newDestinationValWithoutWhitespace = newDestinationValue.value.replace(/\s/g, "");
  if (paymentMethods) {
    const selectedBank = paymentMethods.find(method => method.id === newDestinationValWithoutWhitespace);
    const label =
      selectedBank?.paymentMethodType === PaymentMethodType.CBIT ? selectedBank?.walletName : selectedBank?.displayName;
    return Boolean(selectedBank)
      ? {
          uuid: selectedBank.id,
          value: newDestinationValWithoutWhitespace,
          label,
        }
      : { value: newDestinationValWithoutWhitespace, label: "" };
  }
  if (whitelist) {
    const whiteListedAddress: WhitelistAddress | null = whitelist.find(
      w =>
        w.address.wrapped === newDestinationValWithoutWhitespace &&
        (w.memo ? w.memo === newDestinationValue.memo : true)
    );
    return {
      whitelist: true,
      attested: whiteListedAddress.attested,
      chainNetwork: whiteListedAddress.chainNetwork,
      value: newDestinationValWithoutWhitespace,
      label: whiteListedAddress?.label || newDestinationValWithoutWhitespace,
      memo: whiteListedAddress.memo,
    };
  }

  return { value: newDestinationValWithoutWhitespace, label: newDestinationValWithoutWhitespace };
};

export const makeDestinationKey = (destinationValue: string, destinationMemo?: string) => {
  const memoSuffix = destinationMemo ? `_${destinationMemo}` : "";
  return `${destinationValue}${memoSuffix}`;
};

export const getDisabledByAttestation = (attestation: OutboundAttestation) => {
  const { attestationType, addressType } = attestation;

  const isThirdParty = attestationType === AttestationTypeRadioValues.ThirdParty;
  const thirdPartyChecks =
    !attestation?.recipientType ||
    !attestation?.country ||
    (attestation?.recipientType === RecipientTypeRadioValues.Entity && !attestation?.entityName) ||
    (attestation?.recipientType === RecipientTypeRadioValues.Individual &&
      (!attestation?.firstName || !attestation?.lastName));

  return (
    !addressType ||
    !attestationType ||
    (addressType === DestinationTypeRadioValues.Vasp && !attestation?.vaspId) ||
    (isThirdParty && thirdPartyChecks)
  );
};

export const checkUnsupported = (unsupportedUkWithdrawalList: { tokens: string[] }, currency: CurrencyShortName) =>
  unsupportedUkWithdrawalList.tokens.some((token: string) => token === currency);
