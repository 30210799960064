import optimizelySDK, { enums, EventDispatcher } from "@optimizely/optimizely-sdk";
import * as Sentry from "@sentry/browser";
import { isAfter } from "date-fns";
import Cookies from "js-cookie";
import { each } from "lodash";
import { Events } from "@gemini-ui/analytics/constants/events";
import { mixpanelClient } from "@gemini-ui/analytics/mixpanel";
import mockOptimizelyInstance from "@gemini-ui/analytics/optimizelyMock";
import { Experiments } from "@gemini-ui/constants/abTestExperiments";
import { TemplateProps } from "@gemini-ui/constants/templateProps";
import { GeminiEntity } from "@gemini-ui/constants/templateProps/account";
import { User } from "@gemini-ui/constants/templateProps/users";
import axios from "@gemini-ui/services/axios";

let OPTIMIZELY_OVERRIDES = null;

/**
 * Prevent requests to the Optimizely results backend. Use this to disable
 * all event dispatching.
 */
const logOnlyEventDispatcher: EventDispatcher = {
  dispatchEvent(): void {
    // don't do anything
  },
};

const getOptimizelyOverrides = () => {
  if (window.__DEV__) {
    try {
      // Setting a require context here allows us to avoid log warnings if the file doesn't exist
      OPTIMIZELY_OVERRIDES = require.context("./", false, /\.json$/)("./optimizelyOverrides.json");
      each(OPTIMIZELY_OVERRIDES, (value, key) =>
        console.info(`[Optimizely overrides] "${key}" set to ${JSON.stringify(value)}.`)
      );
    } catch (err) {}
  }
};

getOptimizelyOverrides();

let userOptOut = false;
// This will be overridden when the client is initialized
let optimizelyInstance = mockOptimizelyInstance;
let optimizelyCookieOverrides = {};
interface UserCohortAttributes {
  cohort?: string; // Server defined cohort
  emailAddress: string;
  domain: string;
  platform: "web";
  isGeminiEmployee: boolean;
  isInstitutional: boolean;
  isFullyVerified: boolean;
  advancedTradeUIEnabled: boolean;
  gcTradingEnabled: boolean;
  countryCode: string;
  geminiEntity: string;
  stateCode: string;
  // @deprecated
  containsAnalyticsId: string;
  analyticsId: string;
}

// We get these from the template props and they are also modified by `setAttributes`
const userAttributes: UserCohortAttributes = {
  platform: "web",
  emailAddress: "",
  domain: window.__DEV__ ? "localhost" : window.location.hostname,
  isGeminiEmployee: false,
  isInstitutional: false,
  isFullyVerified: false,
  advancedTradeUIEnabled: false,
  gcTradingEnabled: false,
  countryCode: "",
  geminiEntity: "",
  stateCode: "",
  containsAnalyticsId: "",
  analyticsId: "",
};

optimizelySDK.setLogger(optimizelySDK.logging.createLogger());
optimizelySDK.setLogLevel(enums.LOG_LEVEL.ERROR);

export type OptimizelyExpVariationResponse = "control" | "treatment";

export const optimizelyClient = {
  getInstance: () => optimizelyInstance,
  setAttributes: (attributes: Partial<UserCohortAttributes>) => Object.assign(userAttributes, attributes),
  /**
   * @deprecated please leverage isFeatureEnabled and getting feature variables instead
   * to determine experiment variations
   */
  activate: (experimentName: Experiments, attributes?: {}) =>
    optimizelyInstance.activate(experimentName, mixpanelClient.getMixpanelId(), attributes),
  track: (eventKey: Events, attributes?: {}, eventTags?: {}) =>
    !userOptOut &&
    optimizelyInstance.track(
      eventKey,
      mixpanelClient.getMixpanelId(),
      Object.assign(userAttributes, attributes),
      eventTags
    ),
  trackExposure: (experimentKey: string, variationKey: string) => {
    axios
      .post("exp/exposure", {
        experimentKey,
        analyticsId: mixpanelClient.getMixpanelId(),
        variant: variationKey,
      })
      .catch(error => {
        Sentry.captureException(error);
      });
  },
  getVariation: (experimentKey: string, attributes?: {}) => {
    const variation = optimizelyInstance.getVariation(
      experimentKey,
      mixpanelClient.getMixpanelId(),
      Object.assign(userAttributes, attributes)
    );
    mixpanelClient.setSuperProperty({ [`Experiment: ${experimentKey}`]: variation });
    return variation;
  },
  getExpVariation: (experimentKey: string) => {
    // ensures consistent bucketing with web/BE/mobile
    return axios.get<OptimizelyExpVariationResponse>(`exp/variant/${experimentKey}`);
  },
  setForcedVariation: (experimentKey: string, variationKey: string | null) =>
    optimizelyInstance.setForcedVariation(experimentKey, mixpanelClient.getMixpanelId(), variationKey),
  getForcedVariation: (experimentKey: string) =>
    optimizelyInstance.getForcedVariation(experimentKey, mixpanelClient.getMixpanelId()),
  isFeatureEnabled: (featureKey: string, attributes?: {}, userId: string = mixpanelClient.getMixpanelId()): boolean => {
    // only return if key exists in override, otherwise read from datafile
    if (optimizelyCookieOverrides.hasOwnProperty(featureKey)) {
      return optimizelyCookieOverrides[featureKey];
    }
    if (window.__DEV__ && OPTIMIZELY_OVERRIDES) {
      // allow developers to locally override optimizely flags
      if (OPTIMIZELY_OVERRIDES.hasOwnProperty(featureKey)) {
        return OPTIMIZELY_OVERRIDES[featureKey];
      }
    }

    const isEnabled = optimizelyInstance.isFeatureEnabled(featureKey, userId, {
      analyticsId: userId,
      ...userAttributes,
      ...attributes,
    });

    return isEnabled;
  },
  getEnabledFeatures: (attributes?: {}) =>
    optimizelyInstance.getEnabledFeatures(mixpanelClient.getMixpanelId(), Object.assign(userAttributes, attributes)),
  getFeatureVariableBoolean: (featureKey: string, variableKey: string, attributes?: {}) =>
    optimizelyInstance.getFeatureVariableBoolean(
      featureKey,
      variableKey,
      mixpanelClient.getMixpanelId(),
      Object.assign(userAttributes, attributes)
    ),
  getFeatureVariableDouble: (featureKey: string, variableKey: string, attributes?: {}) =>
    optimizelyInstance.getFeatureVariableDouble(
      featureKey,
      variableKey,
      mixpanelClient.getMixpanelId(),
      Object.assign(userAttributes, attributes)
    ),
  getFeatureVariableInteger: (featureKey: string, variableKey: string, attributes?: {}) =>
    optimizelyInstance.getFeatureVariableInteger(
      featureKey,
      variableKey,
      mixpanelClient.getMixpanelId(),
      Object.assign(userAttributes, attributes)
    ),
  getFeatureVariableString: (featureKey: string, variableKey: string, attributes?: {}) =>
    optimizelyInstance.getFeatureVariableString(
      featureKey,
      variableKey,
      mixpanelClient.getMixpanelId(),
      Object.assign(userAttributes, attributes)
    ),
  getFeatureVariableJSON: (featureKey: string, variableKey: string, attributes?: {}) => {
    // allow developers to locally override optimizely variables
    if (window.__DEV__ && OPTIMIZELY_OVERRIDES) {
      if (
        OPTIMIZELY_OVERRIDES.hasOwnProperty(featureKey) &&
        OPTIMIZELY_OVERRIDES[featureKey].hasOwnProperty(variableKey)
      ) {
        return OPTIMIZELY_OVERRIDES[featureKey][variableKey];
      }
    }

    return optimizelyInstance.getFeatureVariableJSON(
      featureKey,
      variableKey,
      mixpanelClient.getMixpanelId(),
      Object.assign(userAttributes, attributes)
    );
  },
  optOut: () => {
    userOptOut = true;
  },
  addNotificationListener: (notificationType: string, callback: any) =>
    optimizelyInstance.notificationCenter.addNotificationListener(notificationType, callback),
  removeNotificationListener: (listenerId: number) =>
    optimizelyInstance.notificationCenter.removeNotificationListener(listenerId),
  createUserContext: (userId: string, attributes?: {}) => optimizelyInstance.createUserContext(userId, attributes),
};

export const initializeOptimizely = (
  optimizelyDatafile: object,
  { user, isGeminiEmployee, geminiEntity }: { user?: User; isGeminiEmployee: boolean; geminiEntity: GeminiEntity }
) => {
  // prefer cookie overrides for usage across platforms
  try {
    const cookieOverride = Cookies.get("OptimizelyOverrides");
    if (cookieOverride) {
      optimizelyCookieOverrides = JSON.parse(cookieOverride);
    }
  } catch (e) {
    Sentry.captureException(e);
  }

  if (optimizelyDatafile) {
    getOptimizelyOverrides(); // Check for any optimizelyOverrides
    // Always set the data to avoid flag resets from the mock instance when fetching is required
    optimizelyInstance = optimizelySDK.createInstance({
      datafile: optimizelyDatafile,
      eventDispatcher: logOnlyEventDispatcher,
    });
  }

  if (user) {
    optimizelyClient.setAttributes({
      // leaving out cohort for now because it isn't optional
      // cohort: user.cohort,
      emailAddress: user.email,
      isGeminiEmployee: isGeminiEmployee,
      isInstitutional: user.isInstitutional,
      isFullyVerified: user.isFullyVerified,
      advancedTradeUIEnabled: user.advancedTradeUIEnabled,
      gcTradingEnabled: user.gcTradingEnabled,
      countryCode: user.countryCode,
      geminiEntity: geminiEntity,
      stateCode: user.stateCode || "", // State code is an optional parameter from the BE
      containsAnalyticsId: user.mixpanelId || "", // This is DEPRECATED and should be removed
      analyticsId: user.mixpanelId || "",
    });
  }
};

export const isUserCreatedAfterFeatureLaunchDate = (featureFlag: string, user: TemplateProps["user"]) => {
  const createdAt = user.subaccounts[0]?.createdAt;
  const launchDate = optimizelyClient.getFeatureVariableString(featureFlag, "launch_date") as string;
  return isAfter(new Date(createdAt), new Date(launchDate));
};
