import { SupportedCurrencyPairs } from "@gemini-common/scripts/constants/currencies";
import { MoneyProps } from "@gemini-ui/components/Money";
import { GrowProviderType } from "@gemini-ui/constants/earn";
import { InstrumentType, Side } from "@gemini-ui/constants/orders";
import { UnfulfilledRedeem } from "@gemini-ui/pages/RetailTrade/constants";
import { TransferTypeEnum } from "@gemini-ui/pages/transfers/constants";

export interface ChainAddress {
  $type: string;
  wrapped: string;
}

type TransferStates =
  | "Canceled"
  | "CancelRequested"
  | "Changed"
  | "Deposited"
  | "Failed"
  | "HeldForReview"
  | "Pending"
  | "Recalled"
  | "Returned"
  | "Succeeded"
  | "Unsubmitted"
  | "AttestationRequired";

export interface TransferType extends Partial<UnfulfilledRedeem> {
  transferInfo?: TransferInfo;
  transferType: TransferTypeEnum;
  destination?: string | ChainAddress;
  merchantName?: string;
  txHash?: string;
  depositId?: string;
  hashid?: string;
  status?: string;
  outpoint?: {
    txId?: string | { signature?: string };
    outputIdx: number;
  };
  bankName?: string;
  timestamp?: number;
  transferOn?: number;
  minDateAccrualForMonth?: number;
  maxDateAccrualForMonth?: number;
  amount: MoneyProps;
  fee?: MoneyProps;
  amountWithFee?: {
    amount: MoneyProps;
    fee: MoneyProps;
  };
  value?: {
    amount: MoneyProps;
    fee?: MoneyProps;
  };
  transferError?: string;
  precredit?: boolean;
  complete?: boolean;
  processableAt?: number;
  source?: string;
  redeemedInFull?: boolean;
  redeemFulfilledOn?: number;
  currentStatus?: null | TransferStates;
  providerName?: string;
  providerType?: GrowProviderType;
  type?: string;
  isAwaitingAttestation?: boolean;
  isAwaitingUkAttestation?: boolean;
  isAwaitingEuAttestation?: boolean;
  accrueOn?: number;
  price?: MoneyProps;
  totalPrice?: MoneyProps;
  annotations?: TransferAnnotation;
}

export type TransferInfo =
  | "Debit"
  | "Credit"
  | "WireDeposit"
  | "SenDeposit"
  | "CardDeposit"
  | "WireAdvanceDeposit"
  | "WireWithdrawal"
  | "SenWithdrawal"
  | "InstantAchDeposit"
  | "InstantCardDeposit"
  | "InstantOrderFeeCredit"
  | "RtpDeposit"
  | "RtpWithdrawal"
  | "AchDeposit"
  | "AchWithdrawal"
  | "AchReturn"
  | "WireReturn"
  | "SenReturn"
  | "CardReturn"
  | "CanceledWireAdvance"
  | "CanceledCardAdvance"
  | "XfersDeposit"
  | "XfersWithdrawal"
  | "BlincDeposit"
  | "BlincReturn"
  | "BlincWithdrawal"
  | "BcoloDeposit"
  | "BcoloReturn"
  | "BcoloWithdrawal"
  | "PreCredit"
  | "PendingExecution"
  | "PayPalDeposit"
  | "CanceledPayPalAdvance"
  | "PayPalReturn"
  | "CbitDeposit"
  | "CbitWithdrawal";

interface TransferAnnotation {
  annotationMetadataType: string;
  annotationMetadata: AnnotationMetadata;
}

interface AnnotationMetadata {
  tags: AnnotationMetadataTag[];
}

export enum AnnotationMetadataTag {
  Deposit = "deposit",
  Withdrawal = "withdrawal",
  Reward = "reward",
}

interface LimitOrderProps {
  avgPrice: MoneyProps;
  cancelled: boolean;
  created: number;
  hashid: string;
  isAuction: boolean;
  isInstant: boolean;
  isRecurring: boolean;
  orderSource: string;
  pair: SupportedCurrencyPairs;
  price: MoneyProps;
  quantity: MoneyProps;
  stopPrice?: MoneyProps;
  reason: string;
  remainingQuantity: MoneyProps;
  side: Side;
  totalPrice: MoneyProps;
  updated: number;
  instrumentType: InstrumentType;
}

// Same for MarketOrderBuyProps / MarketOrderSellProps
interface MarketOrderProps {
  accepted: number;
  avgPrice: MoneyProps;
  cancelled: boolean;
  hashid: string;
  isAuction: boolean;
  pair: SupportedCurrencyPairs;
  quantityReceived: MoneyProps;
  reason: string;
  remainingQuantity: MoneyProps;
  side: Side;
  totalSpend: MoneyProps;
  type: string;
  updated: number;
}

interface DollarTransferProps {
  hashid: string;
  timestamp: number;
  amount: MoneyProps;
  transferInfo: TransferInfo;
  bankName?: string;
  userCanCancel: boolean;
  transferType?: string;
  status?: string;
  cancellationWindow: number;
}

interface FiatTransferProps {
  hashid: string;
  timestamp: number;
  amount: MoneyProps;
  transferInfo: TransferInfo;
  bankName?: string;
  userCanCancel: boolean;
  transferType?: string;
  status?: string;
  cancellationWindow: number;
}

// After Full Migrating to the new wallet Bitcoin* types will be deleted in favor of Btc*. see the new types at the end

interface TransferBase {
  timestamp: number;
  amount: MoneyProps;
}

interface MaybeOutpointTransfer {
  outpoint?: {
    txId: string;
    outputIdx: number;
  };
}

interface OutpointTransfer {
  outpoint: {
    txId: string;
    outputIdx: number;
  };
}

interface MoneyWithFee {
  amountWithFee: {
    amount: MoneyProps;
    fee: MoneyProps;
  };
}

interface DestinationTransaction {
  destination?: string;
}

// btcDeposit
type BitcoinDepositProps = TransferBase & OutpointTransfer;

// canceledBtcAdvance
type CanceledBtcAdvanceProps = {
  txHash: string;
} & TransferBase;

// btcWithdrawal

type BitcoinWithdrawalProps = TransferBase & MaybeOutpointTransfer & DestinationTransaction;

//ethDeposit
type EtherDepositProps = {
  txHash: string;
  txIndex: number;
  annotations?: TransferAnnotation;
} & TransferBase;

//erc20Deposit
type Erc20DepositProps = TransferBase & OutpointTransfer;

// ethWithdrawal
type EtherWithdrawalProps = {
  fee?: MoneyProps;
  txHash?: string;
  annotations?: TransferAnnotation;
} & TransferBase &
  DestinationTransaction;

// erc20Withdrawal
type Erc20WithdrawalProps = MoneyWithFee & OutpointTransfer & TransferBase & DestinationTransaction;

// oberonDeposit and oberonWithdrawal
type GusdTransferProps = {
  txHash: string;
  txIndex?: number;
} & DestinationTransaction &
  TransferBase;

// adminTransfer
type adminTransferProps = {
  transferInfo: string;
} & TransferBase;

// zecDeposit
type ZcashDepositProps = TransferBase & OutpointTransfer;

// zecWithdrawal
type ZcashWithdrawalProps = TransferBase & MaybeOutpointTransfer & DestinationTransaction;

// ltcDeposit
type LitecoinDepositProps = TransferBase & OutpointTransfer;

// ltcWithdrawal
type LitecoinWithdrawalProps = TransferBase & MaybeOutpointTransfer & DestinationTransaction;

// bchDeposit
type BitcoinCashDepositProps = TransferBase & OutpointTransfer;

// bchWithdrawal
type BitcoinCashWithdrawalProps = {
  fee?: MoneyProps;
} & TransferBase &
  MaybeOutpointTransfer &
  DestinationTransaction;

type WithdrawalFeeProps = TransferBase;

type CanceledNakamotoAdvanceProps = TransferBase & OutpointTransfer;

// See MarketInfoProvider.scala#LedgerEventProps
export type HistoryEntry = {
  hashid: string;
  type: string;
} & LimitOrderProps &
  MarketOrderProps &
  DollarTransferProps &
  FiatTransferProps &
  BitcoinDepositProps &
  CanceledBtcAdvanceProps &
  BitcoinWithdrawalProps &
  EtherDepositProps &
  Erc20DepositProps &
  EtherWithdrawalProps &
  Erc20WithdrawalProps &
  GusdTransferProps &
  adminTransferProps &
  ZcashDepositProps &
  ZcashWithdrawalProps &
  LitecoinDepositProps &
  LitecoinWithdrawalProps &
  BitcoinCashDepositProps &
  BitcoinCashWithdrawalProps &
  WithdrawalFeeProps &
  CanceledNakamotoAdvanceProps;
