// eslint-disable-next-line no-restricted-imports
import axiosOriginal from "axios";
import { AxiosCacheInstance, setupCache } from "axios-cache-interceptor";
import axios from "@gemini-ui/services/axios";
import { AXIOS_BASE_CONFIG } from "@gemini-ui/services/axios/config";
import { credentialsRequestInterceptor } from "@gemini-ui/services/axios/interceptors/credentialsRequestInterceptor";
import { loginRedirectInterceptor } from "@gemini-ui/services/axios/interceptors/loginRedirectInterceptor";

export const CACHE_ID = {
  accountBalances: "accountBalances",
  marketFilters: "marketFilters",
  lockout: "lockout",
  carouselCards: "carouselCards",
  perpEligible: "perpEligible",
  ccpa: "ccpa",
  watchlist: "watchlist",
} as const;

// create a new axios instance to hold the cache
const axiosInstance = axiosOriginal.create(AXIOS_BASE_CONFIG);
// Use the interceptors from the base axios instance, retaining existing functionality, aside from retry which has issues.
axiosInstance.interceptors.request.use(credentialsRequestInterceptor);
axiosInstance.interceptors.response.use(undefined, loginRedirectInterceptor.bind(axiosInstance));

// add the cache, bypass in test env
const axiosInstanceWithCache =
  process.env.NODE_ENV === "test"
    ? (axios as AxiosCacheInstance)
    : setupCache(axiosInstance, {
        ttl: 1000,
        interpretHeader: false,
      });

export default axiosInstanceWithCache;
