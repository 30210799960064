import {
  IconChevronRightSmall,
  IconDocumentOutlined,
  IconLogOut,
  IconMessageOutlined,
  IconPortfolio,
  IconRewardOutlined,
  IconSettingsOutlined,
  IconSupportOutlined,
} from "@hubble/icons";
import { EVENTS, optimizelyClient, track } from "@gemini-ui/analytics";
import Copyright from "@gemini-ui/components/Footers/Copyright";
import {
  ACCOUNT_MENU_VIEWS,
  TEST_IDS,
} from "@gemini-ui/components/Header/navigation/IconContainer/Menus/AccountMenu/constants";
import { AccountMenuInterfaceSwitcher } from "@gemini-ui/components/Header/navigation/IconContainer/Menus/AccountMenu/DefaultView/AccountMenuSwitcher";
import { AccountMenuTitle } from "@gemini-ui/components/Header/navigation/IconContainer/Menus/AccountMenu/DefaultView/AccountMenuTitle";
import { AccountMenuViewProps } from "@gemini-ui/components/Header/navigation/IconContainer/Menus/AccountMenu/types";
import { handleLogout } from "@gemini-ui/components/Header/navigation/utils";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { usePageData } from "@gemini-ui/contexts";
import { GlobalModalType, useGlobalModal } from "@gemini-ui/contexts/GlobalModal";
import { Flex, HubbleMenu } from "@gemini-ui/design-system";
import axios from "@gemini-ui/services/axios";
import { isDerivativesEnabled } from "@gemini-ui/utils/derivativeAccountUtils";
import { useIntl } from "@gemini-ui/utils/intl";

const ID = "ze-snippet";

declare global {
  interface Window {
    zE: Function;
  }
}

export const AccountMenuDefaultView = ({
  userName,
  institutionName,
  groupsInfo,
  setView,
  onClose,
}: AccountMenuViewProps) => {
  const { intl } = useIntl();
  const { toggleModal } = useGlobalModal();

  const {
    templateProps: { user },
  } = usePageData();

  const isDerivativesAccount = isDerivativesEnabled(user);
  const currentGroup = groupsInfo.groups.find(g => g.hashid === groupsInfo.currentGroupHashId);

  const title = institutionName ? institutionName : userName;
  const titleIconSize = institutionName ? "24px" : "20px";

  const isPermanentFeedbackEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_PERMANENT_FEEDBACK);
  const isZendeskLiveChatEnabled = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_ZENDESK_LIVE_CHAT);
  const isRewardsReferralActive = optimizelyClient.isFeatureEnabled(OPTIMIZELY_FEATURE_FLAGS.WEB_REWARDS_REFERRALS_V2);
  const showRewardsLink = isRewardsReferralActive && !user.isCustodyAccount && !user.isInstitutional;

  const handleNext = e => {
    e.preventDefault();
    setView(ACCOUNT_MENU_VIEWS.group);
  };

  const openChat = () => {
    const zendeskConfig = initialData?.zendesk;
    const apiKey = zendeskConfig?.publicKey;
    const scriptJs = document.createElement("script");
    scriptJs.src = `https://static.zdassets.com/ekr/snippet.js?key=${apiKey}`;
    scriptJs.id = ID;
    scriptJs.async = true;

    document.body && document.body.appendChild(scriptJs);

    scriptJs.onload = async () => {
      const { data } = await axios.get("/livechat/token");
      if (data) {
        window.zE("messenger", "loginUser", callback => {
          callback(data);
        });

        onClose?.();

        window.zE("messenger", "open");
      } else {
        window.open("https://gemini24.zendesk.com/hc/en-us");
      }
    };
  };

  const handleSupportCenterClick = e => {
    if (isZendeskLiveChatEnabled) {
      e.preventDefault();
      openChat();
    }
  };

  const handleGiveFeedbackClick = e => {
    toggleModal(GlobalModalType.PermanentFeedbackModal);
  };

  const handleRewardClick = e => {
    track(EVENTS.REWARD_ENTRY_POINT.name, { [EVENTS.REWARD_ENTRY_POINT.properties.INITIATED_FROM]: "Header" });
  };

  const myAccountDropdownMenuData = [
    {
      icon: <IconSettingsOutlined />,
      name: intl.formatMessage({ defaultMessage: "Settings" }),
      url: jsRoutes.controllers.settings.ProfileSettingsController.get().url,
    },
    ...(showRewardsLink
      ? [
          {
            icon: <IconRewardOutlined />,
            name: intl.formatMessage({ defaultMessage: "Rewards" }),
            url: jsRoutes.com.gemini.web.server.onboarding.controllers.services.RewardsController.getRewardsPage().url,
            onClick: handleRewardClick,
          },
        ]
      : []),
    {
      icon: <IconPortfolio />,
      name: intl.formatMessage({ defaultMessage: "Balances" }),
      url: jsRoutes.com.gemini.web.server.trading.controllers.BalanceControllerV2.get().url,
    },
    ...(isPermanentFeedbackEnabled
      ? [
          {
            icon: <IconMessageOutlined />,
            name: intl.formatMessage({ defaultMessage: "Give feedback" }),
            onClick: handleGiveFeedbackClick,
          },
        ]
      : []),
    {
      icon: <IconSupportOutlined />,
      name: intl.formatMessage({ defaultMessage: "Support center" }),
      onClick: handleSupportCenterClick,
      url: "https://gemini24.zendesk.com/hc/en-us",
      target: "_blank",
    },
    {
      icon: <IconDocumentOutlined />,
      name: intl.formatMessage({ defaultMessage: "Legal" }),
      url: isDerivativesAccount ? "https://www.gemini.com/artemis/legal" : "https://www.gemini.com/legal",
      target: "_blank",
    },
    {
      icon: <IconLogOut />,
      name: intl.formatMessage({ defaultMessage: "Log out" }),
      url: jsRoutes.controllers.account.Application.signOut().url,
      onClick: handleLogout,
    },
  ];

  return (
    <Flex flexDirection="column" width="100%" gap="4px" data-testid={TEST_IDS.account_menu_default_view}>
      {groupsInfo.groups.length === 1 ? (
        <AccountMenuTitle
          groupName={currentGroup.name}
          institutionName={institutionName}
          title={title}
          userName={userName}
        />
      ) : (
        <HubbleMenu.ItemLink
          label=""
          leftElement={
            <AccountMenuTitle
              groupName={currentGroup.name}
              institutionName={institutionName}
              title={title}
              userName={userName}
              isLink
            />
          }
          rightElement={<IconChevronRightSmall width={titleIconSize} height={titleIconSize} />}
          onSelect={handleNext}
        />
      )}
      <HubbleMenu.Separator />
      <AccountMenuInterfaceSwitcher />
      <HubbleMenu.Separator />
      <HubbleMenu.Group>
        {myAccountDropdownMenuData.map((item, index) => (
          <HubbleMenu.ItemLink
            key={item.name}
            data-testid={`${item.name}-link`}
            label={item.name}
            leftElement={item.icon}
            href={item.url}
            target={item.target}
            onSelect={item.onClick}
          />
        ))}
      </HubbleMenu.Group>
      <HubbleMenu.Separator />
      <Flex p={1}>
        <Copyright />
      </Flex>
    </Flex>
  );
};
