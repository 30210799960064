import { IJsonBorderNode, IJsonModel, IJsonTabNode, IJsonTabSetNode } from "flexlayout-react";
import {
  CommonLayoutComponent,
  ModelProps,
  PairLayoutComponent,
  TabSet,
} from "@gemini-ui/pages/ActiveTrader/Spot/TradeLayout/TradeFlexLayout/constants";
import { areCustomLayoutsEnabled } from "@gemini-ui/pages/ActiveTrader/Spot/TradeLayout/TradeFlexLayout/utils";
import { IntlShape } from "@gemini-ui/utils/intl";

const TOP = "top";
const PRIMARY = "primary";
const SECONDARY = "secondary";
const NAVBAR_SIZE = 48;

const commonLayoutTabs = (intl: IntlShape): { [c in CommonLayoutComponent]: IJsonTabNode } => ({
  [CommonLayoutComponent.OPEN_ORDERS]: {
    type: "tab",
    name: intl.formatMessage({ defaultMessage: "Open orders" }),
    component: CommonLayoutComponent.OPEN_ORDERS,
    id: CommonLayoutComponent.OPEN_ORDERS,
    enableDrag: false,
  },
  [CommonLayoutComponent.ORDER_HISTORY]: {
    type: "tab",
    name: intl.formatMessage({ defaultMessage: "Order history" }),
    component: CommonLayoutComponent.ORDER_HISTORY,
    id: CommonLayoutComponent.ORDER_HISTORY,
    enableDrag: false,
  },
  [CommonLayoutComponent.LEADERBOARD]: {
    type: "tab",
    name: intl.formatMessage({ defaultMessage: "Leaderboard" }),
    component: CommonLayoutComponent.LEADERBOARD,
    id: CommonLayoutComponent.LEADERBOARD,
  },
  [CommonLayoutComponent.LEADERBOARD_MOBILE]: {
    type: "tab",
    name: intl.formatMessage({ defaultMessage: "Leaderboard" }),
    component: CommonLayoutComponent.LEADERBOARD_MOBILE,
    id: CommonLayoutComponent.LEADERBOARD_MOBILE,
  },
  [CommonLayoutComponent.ACCOUNT_DETAILS]: {
    type: "tab",
    name: intl.formatMessage({ defaultMessage: "Account details" }),
    component: CommonLayoutComponent.ACCOUNT_DETAILS,
    id: CommonLayoutComponent.ACCOUNT_DETAILS,
  },
  [CommonLayoutComponent.POSITIONS]: {
    type: "tab",
    name: intl.formatMessage({ defaultMessage: "Positions" }),
    component: CommonLayoutComponent.POSITIONS,
    id: CommonLayoutComponent.POSITIONS,
    enableDrag: false,
  },
  [CommonLayoutComponent.TRADE_HISTORY]: {
    type: "tab",
    name: intl.formatMessage({ defaultMessage: "Trade history" }),
    component: CommonLayoutComponent.TRADE_HISTORY,
    id: CommonLayoutComponent.TRADE_HISTORY,
    enableDrag: false,
  },
  [CommonLayoutComponent.LIQUIDATION]: {
    type: "tab",
    name: intl.formatMessage({ defaultMessage: "Liquidation" }),
    component: CommonLayoutComponent.LIQUIDATION,
    id: CommonLayoutComponent.LIQUIDATION,
    enableDrag: false,
  },
  [CommonLayoutComponent.FUNDING]: {
    type: "tab",
    name: intl.formatMessage({ defaultMessage: "Funding" }),
    component: CommonLayoutComponent.FUNDING,
    id: CommonLayoutComponent.FUNDING,
    enableDrag: false,
  },
  [CommonLayoutComponent.TRANSFERS]: {
    type: "tab",
    name: intl.formatMessage({ defaultMessage: "Transfers" }),
    component: CommonLayoutComponent.TRANSFERS,
    id: CommonLayoutComponent.TRANSFERS,
    enableDrag: false,
  },
  [CommonLayoutComponent.REALIZED_PNL]: {
    type: "tab",
    name: intl.formatMessage({ defaultMessage: "Realized P&L" }),
    component: CommonLayoutComponent.REALIZED_PNL,
    id: CommonLayoutComponent.REALIZED_PNL,
    enableDrag: false,
  },
  [CommonLayoutComponent.BALANCES]: {
    type: "tab",
    name: intl.formatMessage({ defaultMessage: "Balances" }),
    component: CommonLayoutComponent.BALANCES,
    id: CommonLayoutComponent.BALANCES,
    enableDrag: false,
  },
  [CommonLayoutComponent.BLOTTER]: {
    type: "tab",
    name: intl.formatMessage({ defaultMessage: "Blotter" }),
    component: CommonLayoutComponent.BLOTTER,
    id: CommonLayoutComponent.BLOTTER,
  },
});

export const pairLayoutTabs = (
  intl: IntlShape,
  type: "primary" | "secondary" = "primary"
): { [c in PairLayoutComponent]: IJsonTabNode } => ({
  [PairLayoutComponent.NAV]: {
    type: "tab",
    name: null,
    component: PairLayoutComponent.NAV,
    id: `${PairLayoutComponent.NAV}-${type}`,
  },
  [PairLayoutComponent.CHART]: {
    type: "tab",
    name: intl.formatMessage({ defaultMessage: "Chart" }),
    component: PairLayoutComponent.CHART,
    id: `${PairLayoutComponent.CHART}-${type}`,
  },
  [PairLayoutComponent.ORDER_BOOK]: {
    type: "tab",
    name: intl.formatMessage({ defaultMessage: "Order book" }),
    component: PairLayoutComponent.ORDER_BOOK,
    id: `${PairLayoutComponent.ORDER_BOOK}-${type}`,
  },
  [PairLayoutComponent.EXCHANGE_ACTIVITY]: {
    type: "tab",
    name: intl.formatMessage({ defaultMessage: "Market trades" }),
    component: PairLayoutComponent.EXCHANGE_ACTIVITY,
    id: `${PairLayoutComponent.EXCHANGE_ACTIVITY}-${type}`,
  },
  [PairLayoutComponent.ORDER_FORM]: {
    type: "tab",
    name: intl.formatMessage({ defaultMessage: "Order form" }),
    component: PairLayoutComponent.ORDER_FORM,
    id: `${PairLayoutComponent.ORDER_FORM}-${type}`,
  },
  [PairLayoutComponent.SUB_LAYOUT]: {
    type: "tab",
    name: null,
    component: PairLayoutComponent.SUB_LAYOUT,
    id: `${PairLayoutComponent.SUB_LAYOUT}-${type}`,
  },
  [PairLayoutComponent.TRADING_VIEW_CHART]: {
    type: "tab",
    name: intl.formatMessage({ defaultMessage: "TradingView chart" }),
    component: PairLayoutComponent.TRADING_VIEW_CHART,
    id: `${PairLayoutComponent.TRADING_VIEW_CHART}-${type}`,
  },
  [PairLayoutComponent.DEPTH_CHART]: {
    type: "tab",
    name: intl.formatMessage({ defaultMessage: "Depth chart" }),
    component: PairLayoutComponent.DEPTH_CHART,
    id: `${PairLayoutComponent.DEPTH_CHART}-${type}`,
  },
});

const GLOBAL_LAYOUT_CONFIG: IJsonModel["global"] = {
  tabSetEnableSingleTabStretch: false,
  splitterSize: 4,
  splitterExtra: 2,
  tabSetEnableMaximize: false,
  tabSetEnableClose: false,
  tabSetTabStripHeight: 56,
  tabEnableRename: false,
  tabSetMinHeight: 224,
  tabSetMinWidth: 320,
  tabEnableClose: false,
};

const getGlobalLayoutConfig = (): IJsonModel["global"] => ({
  ...GLOBAL_LAYOUT_CONFIG,
  tabSetEnableDrag: areCustomLayoutsEnabled(),
  tabEnableDrag: areCustomLayoutsEnabled(),
});

const navbarTop = (NAV: IJsonTabNode, size?: number): IJsonBorderNode => ({
  type: "border",
  enableDrop: false,
  enableAutoHide: false,
  barSize: 2, // if 0, value will be calculated from the current fontSize
  minSize: NAVBAR_SIZE,
  size: size ?? NAVBAR_SIZE,
  location: TOP,
  selected: 0,
  children: [NAV],
});

export const mobileLayoutModel = (
  intl: IntlShape,
  { isDerivativesAcct, showDerivativeBlotters, showOnlyOrderForm }: ModelProps
): IJsonModel => {
  const {
    OPEN_ORDERS,
    ORDER_HISTORY,
    ACCOUNT_DETAILS,
    POSITIONS,
    TRADE_HISTORY,
    LIQUIDATION,
    FUNDING,
    TRANSFERS,
    REALIZED_PNL,
    BALANCES,
    LEADERBOARD_MOBILE,
    BLOTTER,
  } = commonLayoutTabs(intl);
  const { NAV, CHART, ORDER_BOOK, EXCHANGE_ACTIVITY, ORDER_FORM, TRADING_VIEW_CHART, DEPTH_CHART } =
    pairLayoutTabs(intl);

  const blotters = showDerivativeBlotters
    ? [POSITIONS, OPEN_ORDERS, TRADE_HISTORY, ORDER_HISTORY, LIQUIDATION, FUNDING, TRANSFERS, REALIZED_PNL]
    : [BALANCES, OPEN_ORDERS, TRADE_HISTORY, ORDER_HISTORY];
  const BLOTTERS = {
    ...BLOTTER,
    contentClassName: "blotterContent",
    config: {
      model: {
        global: getGlobalLayoutConfig(),
        borders: null,
        layout: {
          type: "row",
          children: [
            {
              type: "tabset",
              weight: 100,
              active: true,
              enableDrag: false,
              selected: 0,
              children: [...blotters],
            },
          ],
        },
      },
    },
  };
  const layoutChildren = [
    BLOTTERS,
    ...(isDerivativesAcct ? [ACCOUNT_DETAILS] : []),
    ...(areCustomLayoutsEnabled() ? [TRADING_VIEW_CHART, DEPTH_CHART] : [CHART]),
    ORDER_BOOK,
    EXCHANGE_ACTIVITY,
    ...(isDerivativesAcct ? [LEADERBOARD_MOBILE] : []),
  ];
  return {
    global: {
      ...getGlobalLayoutConfig(),
      tabSetEnableDrag: false,
      tabSetEnableDrop: false,
      tabSetEnableDivide: false,
      tabEnableDrag: false,
    },
    // as all the positions are absolute in mobile layout, we need to set the height of the navbar. Mobile has two rows with NAVBAR_SIZE height each (NAVBAR_SIZE * 2)
    borders: [navbarTop(NAV, NAVBAR_SIZE * 2)],
    layout: {
      type: "row",
      children: [
        {
          type: "tabset",
          weight: 100,
          active: true,
          enableDrag: false,
          selected: 0,
          children: showOnlyOrderForm ? [ORDER_FORM] : layoutChildren,
          ...(showOnlyOrderForm ? { tabStripHeight: 12 } : {}),
        },
      ],
    },
  };
};

const leaderboardTabset = (LEADERBOARD: IJsonTabNode): IJsonTabSetNode => ({
  type: "tabset",
  tabStripHeight: 12,
  enableDivide: false,
  enableTabStrip: true, // optionally make this false and give padding in the leaderboard component
  height: 56,
  minHeight: 56,
  children: [LEADERBOARD],
  id: TabSet.LEADERBOARD,
});

const accountDetailsTabset = (ACCOUNT_DETAILS: IJsonTabNode, isDualMarket: boolean): IJsonTabSetNode => ({
  type: "tabset",
  height: isDualMarket ? undefined : 176,
  ...(!areCustomLayoutsEnabled() ? { tabStripHeight: 12 } : {}),
  minHeight: 120,
  children: [ACCOUNT_DETAILS],
  id: TabSet.ACCOUNT_DETAILS,
});

const accountDetailsAndLeaderboardTabset = (
  ACCOUNT_DETAILS: IJsonTabNode,
  LEADERBOARD: IJsonTabNode,
  { isDerivativesAcct, displayLeaderboardModule, isDualMarket }: ModelProps
): IJsonTabSetNode => ({
  type: "tabset",
  height: isDualMarket ? undefined : 236,
  ...(!areCustomLayoutsEnabled() ? { tabStripHeight: 12 } : {}),
  minHeight: 120,
  children: [...(isDerivativesAcct ? [ACCOUNT_DETAILS] : []), ...(displayLeaderboardModule ? [LEADERBOARD] : [])],
  id: TabSet.ACCOUNT_DETAILS_AND_LEADERBOARD,
});

const unnameTab = (children: IJsonTabNode): IJsonTabNode => ({ ...children, name: null });

const pairLayoutModel = (intl: IntlShape, type: "primary" | "secondary"): IJsonTabSetNode => {
  const { NAV, CHART, ORDER_BOOK, EXCHANGE_ACTIVITY, ORDER_FORM, SUB_LAYOUT, TRADING_VIEW_CHART, DEPTH_CHART } =
    pairLayoutTabs(intl, type);
  return {
    type: "tabset",
    id: `pairTabset-${type}`,
    weight: 50,
    active: true,
    tabStripHeight: 1,
    //This is an special tabset that will contain the pair layout, we don't allow dropping from the outside
    enableDrop: false,
    minWidth: GLOBAL_LAYOUT_CONFIG.tabSetMinWidth * 2 + 12 + 2, // 12px padding + 2px for borders (see .flexlayout__tab)
    children: [
      {
        ...SUB_LAYOUT,
        contentClassName: "pairTabsetContent",
        config: {
          model: {
            global: getGlobalLayoutConfig(),
            borders: [navbarTop(NAV)],
            layout: {
              type: "row",
              children: [
                {
                  type: "tabset",
                  weight: 60,
                  children: [
                    ORDER_BOOK,
                    EXCHANGE_ACTIVITY,
                    ...(areCustomLayoutsEnabled() ? [TRADING_VIEW_CHART, DEPTH_CHART] : [CHART]),
                  ],
                },
                {
                  type: "tabset",
                  weight: 40,
                  minWidth: GLOBAL_LAYOUT_CONFIG.tabSetMinWidth,
                  ...(!areCustomLayoutsEnabled() ? { tabStripHeight: 12 } : {}),
                  children: [ORDER_FORM],
                },
              ],
            },
          },
        },
      },
    ],
  };
};

export const dualMarketsLayoutModel = (intl: IntlShape, modelProps: ModelProps): IJsonModel => {
  const {
    OPEN_ORDERS,
    ORDER_HISTORY,
    LEADERBOARD,
    ACCOUNT_DETAILS,
    POSITIONS,
    TRADE_HISTORY,
    LIQUIDATION,
    FUNDING,
    TRANSFERS,
    REALIZED_PNL,
    BALANCES,
  } = commonLayoutTabs(intl);
  const { isDerivativesAcct, displayLeaderboardModule, showDerivativeBlotters } = modelProps;
  return {
    global: { ...getGlobalLayoutConfig(), rootOrientationVertical: true },
    layout: {
      type: "row",
      children: [
        {
          type: "row",
          weight: 70,
          children: [pairLayoutModel(intl, PRIMARY), pairLayoutModel(intl, SECONDARY)],
        },
        {
          type: "row",
          weight: 30,
          height: isDerivativesAcct ? 236 : 224,
          children: [
            {
              type: "tabset",
              id: TabSet.BLOTTERS,
              enableDrop: false,
              weight: 80,
              selected: 0,
              minHeight: 180,
              minWidth: 600,
              children: showDerivativeBlotters
                ? [POSITIONS, OPEN_ORDERS, TRADE_HISTORY, ORDER_HISTORY, LIQUIDATION, FUNDING, TRANSFERS, REALIZED_PNL]
                : [BALANCES, OPEN_ORDERS, TRADE_HISTORY, ORDER_HISTORY],
            },
            {
              type: "row",
              weight: 20,
              children: [
                ...(areCustomLayoutsEnabled()
                  ? isDerivativesAcct || displayLeaderboardModule
                    ? [accountDetailsAndLeaderboardTabset(ACCOUNT_DETAILS, LEADERBOARD, modelProps)]
                    : []
                  : [
                      ...(isDerivativesAcct ? [accountDetailsTabset(ACCOUNT_DETAILS, true)] : []),
                      ...(displayLeaderboardModule ? [leaderboardTabset(LEADERBOARD)] : []),
                    ]),
              ],
            },
          ],
        },
      ],
    },
  };
};

export const desktopLayoutModel = (intl: IntlShape, modelProps: ModelProps): IJsonModel => {
  const {
    OPEN_ORDERS,
    ORDER_HISTORY,
    LEADERBOARD,
    ACCOUNT_DETAILS,
    POSITIONS,
    TRADE_HISTORY,
    LIQUIDATION,
    FUNDING,
    TRANSFERS,
    REALIZED_PNL,
    BALANCES,
  } = commonLayoutTabs(intl);
  const { NAV, CHART, ORDER_BOOK, EXCHANGE_ACTIVITY, ORDER_FORM, TRADING_VIEW_CHART, DEPTH_CHART } =
    pairLayoutTabs(intl);
  const { isDerivativesAcct, displayLeaderboardModule, showDerivativeBlotters } = modelProps;
  const chartTabset: IJsonTabSetNode = areCustomLayoutsEnabled()
    ? {
        type: "tabset",
        id: TabSet.CHART,
        weight: 66,
        selected: 0,
        children: [TRADING_VIEW_CHART, DEPTH_CHART],
      }
    : {
        type: "tabset",
        id: TabSet.CHART,
        weight: 66,
        tabStripHeight: 12,
        children: [unnameTab(CHART)],
      };

  return {
    global: getGlobalLayoutConfig(),
    borders: [navbarTop(NAV)],
    layout: {
      type: "row",
      children: [
        {
          type: "row",
          weight: 80,
          children: [
            {
              type: "row",
              weight: 66,
              children: [
                chartTabset,
                {
                  type: "tabset",
                  id: TabSet.MARKET_AND_ORDERBOOK,
                  weight: 34,
                  minWidth: GLOBAL_LAYOUT_CONFIG.tabSetMinWidth,
                  width: 360,
                  selected: 0,
                  children: [ORDER_BOOK, EXCHANGE_ACTIVITY],
                },
              ],
            },
            {
              type: "tabset",
              id: TabSet.BLOTTERS,
              enableDrop: false,
              weight: 34,
              selected: 0,
              minHeight: 180,
              minWidth: 600,
              height: isDerivativesAcct ? 236 : 224,
              children: showDerivativeBlotters
                ? [POSITIONS, OPEN_ORDERS, TRADE_HISTORY, ORDER_HISTORY, LIQUIDATION, FUNDING, TRANSFERS, REALIZED_PNL]
                : [BALANCES, OPEN_ORDERS, TRADE_HISTORY, ORDER_HISTORY],
            },
          ],
        },
        {
          type: "row",
          weight: 20,
          children: [
            {
              id: TabSet.ORDER_FORM,
              minWidth: GLOBAL_LAYOUT_CONFIG.tabSetMinWidth,
              type: "tabset",
              active: true,
              ...(!areCustomLayoutsEnabled() ? { tabStripHeight: 12 } : {}),
              children: [ORDER_FORM],
            },
            ...(areCustomLayoutsEnabled()
              ? isDerivativesAcct || displayLeaderboardModule
                ? [accountDetailsAndLeaderboardTabset(ACCOUNT_DETAILS, LEADERBOARD, modelProps)]
                : []
              : [
                  ...(isDerivativesAcct ? [accountDetailsTabset(ACCOUNT_DETAILS, false)] : []),
                  ...(displayLeaderboardModule ? [leaderboardTabset(LEADERBOARD)] : []),
                ]),
          ],
        },
      ],
    },
  };
};

export const emptyLayoutModel = (intl: IntlShape): IJsonModel => {
  const { NAV } = pairLayoutTabs(intl);
  return {
    global: getGlobalLayoutConfig(),
    borders: [navbarTop(NAV)],
    layout: {
      type: "row",
      children: [],
    },
  };
};
