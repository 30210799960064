import { FC, PropsWithChildren } from "react";
import { GeminiPaymentsDisclaimerContainer } from "@gemini-ui/components/RegulatoryFooter/styles";
import { usePageData } from "@gemini-ui/contexts";
import { Text } from "@gemini-ui/design-system";
import { useIntl } from "@gemini-ui/utils/intl";

interface Props {
  isFiatTransaction: boolean;
}

export const RegulatoryFooter: FC<PropsWithChildren<Props>> = ({ isFiatTransaction }: Props) => {
  const {
    templateProps: {
      account: { geminiEntity },
    },
  } = usePageData();
  const { intl } = useIntl();
  const createFooter = (text: string) => (
    <GeminiPaymentsDisclaimerContainer mt={4} mb={4}>
      <Text.Body bold size="md">
        {text}
      </Text.Body>
    </GeminiPaymentsDisclaimerContainer>
  );

  switch (geminiEntity) {
    case "GeminiPayments": {
      if (isFiatTransaction) {
        return createFooter(
          intl.formatMessage({
            defaultMessage:
              "Gemini Payments Europe Limited, trading as Gemini is regulated by the Central Bank of Ireland.",
          })
        );
      } else {
        return createFooter(
          intl.formatMessage({
            defaultMessage:
              "Gemini Intergalactic Europe Limited is registered with the Central Bank of Ireland as a virtual asset service provider.",
          })
        );
      }
    }
    default: {
      return null;
    }
  }
};
