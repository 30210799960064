import { useTheme } from "@emotion/react";
import { HubbleCard, HubbleListItem, List, Text } from "@gemini-ui/design-system";
import {
  RtpBankRegistrationForm,
  SupportedBanks,
} from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/constants";
import { useIntl } from "@gemini-ui/utils/intl";

export interface ConfirmationScreenProps {
  bankList: SupportedBanks;
  values: RtpBankRegistrationForm;
}

export const ConfirmationScreen = ({
  values: { nameOnAccount, bankCode, accountNumber },
  bankList,
}: ConfirmationScreenProps) => {
  const { intl } = useIntl();
  const { colorScheme } = useTheme();

  const bankName = bankList.find(bank => bank.bankAbbreviation === bankCode)?.bankName;
  return (
    <HubbleCard density="lg" variant="filled">
      <List>
        <HubbleListItem
          density="lg"
          hasDivider
          left={
            <Text.Body size="sm" color={colorScheme.content.secondary}>
              {intl.formatMessage({ defaultMessage: "Account holder’s full name" })}
            </Text.Body>
          }
          right={<Text.Body size="sm">{nameOnAccount}</Text.Body>}
        />
        <HubbleListItem
          density="lg"
          hasDivider
          left={
            <Text.Body size="sm" color={colorScheme.content.secondary}>
              {intl.formatMessage({ defaultMessage: "Bank name" })}
            </Text.Body>
          }
          right={<Text.Body size="sm">{bankName}</Text.Body>}
        />
        <HubbleListItem
          density="lg"
          hasDivider
          left={
            <Text.Body size="sm" color={colorScheme.content.secondary}>
              {intl.formatMessage({ defaultMessage: "Bank account number" })}
            </Text.Body>
          }
          right={<Text.Body size="sm">{accountNumber}</Text.Body>}
        />
      </List>
    </HubbleCard>
  );
};
