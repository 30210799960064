import {
  ChainNetworkShortName,
  CurrenciesForPeerToPeer,
  CurrencyShortName,
  CurrencyShortNameCrypto,
  CurrencyShortNameFiat,
  CurrencyShortNameForCustody,
} from "@gemini-common/scripts/constants/currencies";
import { AuthyPushStatus } from "@gemini-ui/components/AuthyOneTouch/Status";
import { SiteLockout } from "@gemini-ui/components/Lockout/utils";
import { MoneyProps } from "@gemini-ui/components/Money";
import { CurrencyBalances, CurrencyRiskBalances } from "@gemini-ui/constants/balances";
import { Form } from "@gemini-ui/constants/form";
import { TemplateProps } from "@gemini-ui/constants/templateProps";
import { GeminiAccount, Subaccount, Subaccounts } from "@gemini-ui/constants/templateProps/account";
import { User } from "@gemini-ui/constants/templateProps/users";
import { DepositMetaData } from "@gemini-ui/pages/transfers/Deposit/DepositSuccessModal";
import { WalletAddressStatus } from "@gemini-ui/services/crypto/addressBook";
import { PaymentMethodDataType, TransfersFiatDetailsType } from "@gemini-ui/transformers/PaymentMethods";
import { IntlShape } from "@gemini-ui/utils/intl";

export type TransferType = "between" | "deposit" | "withdraw";

export const enum TransferSections {
  between = "between",
  deposit = "deposit",
  withdraw = "withdraw",
}

export const TRANSFER_MECHANISM = {
  ach: "ach",
  wire: "wire",
  sen: "sen",
  external: "external",
  card: "card",
  blinc: "blinc",
  xfers: "xfers",
  bcolo: "bcolo",
  plaid: "plaid",
  paypal: "paypal",
  rtp: "rtp",
  cbit: "cbit",
} as const;

export type TransferMechanism = keyof typeof TRANSFER_MECHANISM;
export const TRANSFER_MECHANISMS_WITH_FEES = [
  TRANSFER_MECHANISM.wire,
  TRANSFER_MECHANISM.xfers,
  TRANSFER_MECHANISM.rtp,
] as const;

export type Step = "source" | "destination" | "amount" | "complete";
export enum Steps {
  source = "source",
  destination = "destination",
  amount = "amount",
  complete = "complete",
}

export type CurrencyWithBoolean = { [currency in CurrencyShortName]?: boolean };

export const TransferMechanismName = {
  AchTransfer: "AchTransfer",
  BcoloTransfer: "BcoloTransfer",
  BlincTransfer: "BlincTransfer",
  CardTransfer: "CardTransfer",
  PayPalTransfer: "PayPalTransfer",
  PlaidPaymentInitiation: "PlaidPaymentInitiation",
  SenTransfer: "SenTransfer",
  WireTransfer: "WireTransfer",
  XfersTransfer: "XfersTransfer",
  ExternalTransfer: "ExternalTransfer",
  RtpTransfer: "RtpTransfer",
  CbitTransfer: "CbitTransfer",
} as const;

export type KeyOfTransferMechanismName = keyof typeof TransferMechanismName;

export type FiatPermission = {
  [currency in CurrencyShortNameFiat]?: {
    [key in KeyOfTransferMechanismName]?: boolean;
  };
};
export interface TransferAccount extends Subaccount {
  balances?: CurrencyBalances;
  riskBalances?: CurrencyRiskBalances;
}

export type TransferAmount = number | string;

export interface TransferPanelProps {
  accounts: TransferAccount[];
  hasAccountBalances?: boolean;
  isAccountBalancesLoading?: boolean;
  addIntermediaryEnabled: boolean;
  canAddAddress?: CurrencyWithBoolean;
  canDepositFiat?: FiatPermission;
  canWithdraw?: CurrencyWithBoolean;
  canWithdrawFiat?: FiatPermission;
  cancelTransferEnabled: boolean;
  defaultCurrency?: CurrencyShortName;
  defaultFiat: GeminiAccount["defaultFiat"];
  docsApproved?: boolean;
  email: User["email"];
  exchangeOnlyAssets?: CurrencyShortName[];
  inOberonOperatingArea?: boolean;
  isMultiAccount: boolean;
  isMultiUserAccount?: boolean;
  lockout?: SiteLockout;
  numWithdrawalsThisMonth?: { [key in CurrencyShortName]?: number };
  onSuccess: (message: string | React.ReactNode) => void;
  sandboxWalletEnabled: boolean;
  setAccounts: (accounts: TransferAccount[] | ((prevAccounts: TransferAccount[]) => TransferAccount[])) => void;
  source?: TransferAccount;
  sovosTaxStatementRetrieval: boolean;
  subaccounts: Subaccounts;
  supportedAssets: Partial<CurrencyShortName>[];
  supportedExchangeCrypto: Partial<CurrencyShortNameForCustody>[];
  supportedFiat: Partial<CurrencyShortNameFiat>[];
  templateProps: TemplateProps;
  transactionsEnabled?: CurrencyWithBoolean;
  canShowTransferControlAlert?: boolean;
}

export interface TransferStepProps {
  currency?: CurrencyShortName | CurrenciesForPeerToPeer;
  children?: React.ReactNode;
  focused: boolean;
  completed: boolean;
  onEdit: () => void;
}

interface DepositDestinationProps {
  accounts: TransferAccount[];
  setAccounts: (accounts: TransferAccount[]) => void;
  onDestinationChange: (destination: TransferAccount | null) => void;
  onDestinationSelection: () => void;
  destination?: TransferAccount;
  exchangeOnlyAssets?: CurrencyShortName[];
  inOberonOperatingArea?: boolean;
  lockout?: SiteLockout;
  loading?: boolean;
  hasSingleDestination: boolean;
  supportedFiat: GeminiAccount["supportedFiat"];
}

export type DepositDestinationWithTransferStepProps = DepositDestinationProps &
  TransferStepProps & {
    goToStep?: (stepName: Step) => () => void;
  };

export interface TransferDestination
  extends Partial<Pick<WhitelistAddress, "chainNetwork" | "attested" | "hashId" | "accountOrGroup">> {
  // Whether or not the destination is from the whitelist
  whitelist?: boolean;
  label?: string;
  uuid?: string;
  value: string;
  wireApproved?: boolean;
  xfersApproved?: boolean;
  memo?: string;
}

export type RtpWithdrawalLimitsType = {
  maximum: Array<MoneyProps<CurrencyShortNameFiat>>;
  minimum: Array<MoneyProps<CurrencyShortNameFiat>>;
};

/*********************************
 *  Crypto
 **********************************/
export interface WhitelistAddress {
  address: {
    wrapped: string;
    $type: string;
  };
  accountOrGroup: string;
  attested: boolean;
  attestationChangedAt?: string;
  chainNetwork: ChainNetworkShortName;
  createdAt?: number;
  availableAt?: number;
  hashId?: string;
  label?: string;
  name: string;
  requestId?: string;
  requestedBy?: string;
  requestType?: string;
  status?: WalletAddressStatus;
  memo?: string;
  isCustodyMpcEnabled?: boolean;
  isExchangeEnabled?: boolean;
  numPendingMpcApprovals?: number;
  numPendingExchangeApprovals?: number;
  scope?: string;
}

export interface CryptoAddress {
  address: string;
  createdAt?: number;
  isBackCompat?: boolean;
  label?: string;
  memo?: string;
}

export interface UnconfirmedDeposit {
  currency: CurrencyShortNameCrypto;
  value: string;
}

export interface DepositCryptoDetails {
  addresses: CryptoAddress[];
  unconfirmedDeposits: UnconfirmedDeposit;
  updateAddressLabelForm: Form;
  multiAddressFormat: boolean;
  transactionsEnabled?: boolean;
}

export interface WithdrawCryptoDetails {
  form: Form;
  whitelist?: WhitelistAddress[];
  shouldTakeFee: boolean;
  withdrawalFee: MoneyProps;
  monthlyLimit: number;
}

export interface WithdrawCryptoControls {
  areWhitelistsEnabled: boolean;
  travelRuleWithdrawalApprovedAddressAttestationEnabled: boolean;
  whitelistAddresses?: WhitelistAddress[];
}

export interface WithdrawCryptoFee {
  shouldTakeFee: boolean;
  withdrawalFee: {
    currency: CurrencyShortName | null;
    value: string;
  };
  monthlyLimit: number;
  withdrawalFeeId: string;
  feeExpiryInMS: number;
  finalAmount?: {
    currency: CurrencyShortName | null;
    value: string;
  };
}

export interface BetweenPossibleDetails {
  betweenInstantTrade: boolean;
  betweenPossible: boolean;
  destination: CryptoAddress;
  newAddress: boolean;
}

export enum AcknowledgementFlow {
  Take5 = "Take5",
  Worldwide = "Worldwide",
}

export interface CryptoWithdrawAcknowledgement {
  flow: AcknowledgementFlow;
  reason: WithdrawalReason;
}

/*********************************
 *  Common
 **********************************/
export interface LimitType {
  portion: MoneyProps;
  total: MoneyProps;
}

// See MarketInfoProvider.scala#LedgerEventProps
export type TransferTypeEnum =
  | "dollar"
  | "fiat"
  | "oberonWithdrawal"
  | "oberonDeposit"
  | "btcDeposit"
  | "canceledBtcAdvance"
  | "btcWithdrawal"
  | "coinEtfCreation"
  | "coinEtfRedemption"
  | "coinEtfSponsorFees"
  | "ethDeposit"
  | "ethWithdrawal"
  | "oberonDeposit"
  | "oberonWithdrawal"
  | "bchDeposit"
  | "bchWithdrawal"
  | "ltcDeposit"
  | "ltcWithdrawal"
  | "zecDeposit"
  | "zecWithdrawal"
  | "adminTransfer"
  | "canceledNakamotoAdvance"
  | "withdrawalFee"
  | "custodyPreCredit"
  | "internalexchangetransferout"
  | "internalexchangetransferin"
  | "flexaSpend"
  | "giftingCryptoDebit"
  | "giftingCryptoCredit"
  | "earnDeposit"
  | "earnRedeem"
  | "earnAdminRedeem"
  | "earnInterest"
  | "custodyFeeDebit"
  | "riaFeeCredit"
  | "riaFeeDebit"
  | "rewardsDeposit";

export interface AjaxSuccessData {
  hash?: string;
  status: {
    $type: AuthyPushStatus;
  };
  message: string;
  transferId: null;
}

export type DepositState = {
  step: Step;
  currency?: CurrencyShortName;
  amount: string;
  destination?: TransferAccount;
  destinationCompleted: boolean;
  selectedPaymentMethod?: PaymentMethodDataType;
  transferMechanism?: TransferMechanism;
  cryptoDetails?: DepositCryptoDetails;
  selectedAddress?: CryptoAddress;
  addresses?: Array<CryptoAddress>;
  isFiat?: boolean;
  detailsLoading?: boolean;
  instructionsStatus: RequestInstructions;
  restoredSession?: boolean;
  unfilteredPaymentMethods?: TransfersFiatDetailsType["paymentMethods"];
  showSuccessModal?: boolean;
  successMetaData?: DepositMetaData;
} & Partial<TransfersFiatDetailsType>; // partial because initial state could be undefined

export enum RequestInstructions {
  INITIAL = "initial",
  FETCHING = "fetching",
  SUCCESS = "success",
  ERROR = "error",
}

export enum BcoloTransferState {
  NONE = "NONE",
  AUTH_ERROR = "AUTH_ERROR",
  NEEDS_2FA = "NEEDS_2FA",
  NEEDS_AUTH = "NEEDS_AUTH",
  SESSION_RESTORED = "SESSION_RESTORED",
}

// If jurisdiction specific logic is added we may have to refactor
export enum WithdrawalReason {
  INVESTING = "Investing",
  OTHER = "Other",
  OWN_WALLET = "OwnWallet",
  PAYING_GOODS_AND_SERVICES = "PayingGoodAndServices",
  PAYING_OTHERS = "PayingFriendsFamilyPartner",
}

export enum StepupResultLogs {
  SUCCESS = "trust_and_safety.stepup.sli.success",
  TIMEOUT = "trust_and_safety.stepup.sli.timeout",
}

export const getComponentCopy = (intl: IntlShape) => ({
  ADDRESS_LABEL: intl.formatMessage({
    defaultMessage: "Destination address",
  }),
  ADDRESS_SELECT_PLACEHOLDER: intl.formatMessage({
    defaultMessage: "Select destination address",
  }),
  ADDRESS_INPUT_PLACEHOLDER: intl.formatMessage({
    defaultMessage: "Enter destination address",
  }),
  DESTINATION_LEGEND: intl.formatMessage({
    defaultMessage: "Where are you transferring to?",
  }),
  RECIPIENT_LEGEND: intl.formatMessage({
    defaultMessage: "Who is the recipient of this transfer?",
  }),
  RECIPIENT_TYPE_LEGEND: intl.formatMessage({
    defaultMessage: "Is the owner of the receiving wallet an individual or entity?",
  }),
  EXCHANGE_DESCRIPTION: intl.formatMessage({
    defaultMessage: "Gemini, Coinbase, eToro, OKX , etc.",
  }),
  EXCHANGE_RADIO_LABEL: intl.formatMessage({
    defaultMessage: "An exchange",
  }),
  WALLET_DESCRIPTION: intl.formatMessage({
    defaultMessage: "Metamask, Best Wallet, Ledger, Trezor, etc.",
  }),
  WALLET_RADIO_LABEL: intl.formatMessage({
    defaultMessage: "A self-hosted wallet",
  }),
  IDK_DESCRIPTION: intl.formatMessage({
    defaultMessage: "I am not sure if it is an exchange or a self-hosted wallet.",
  }),
  IDK_RADIO_LABEL: intl.formatMessage({
    defaultMessage: "I don’t know",
  }),
  MYSELF_DESCRIPTION: intl.formatMessage({
    defaultMessage: "You are the owner of the receiving wallet.",
  }),
  MYSELF_LABEL: intl.formatMessage({
    defaultMessage: "Myself",
  }),
  SOMEONE_DESCRIPTION: intl.formatMessage({
    defaultMessage: "Someone else is the owner of the receiving wallet.",
  }),
  SOMEONE_LABEL: intl.formatMessage({
    defaultMessage: "Someone else",
  }),
  INDIVIDUAL_LABEL: intl.formatMessage({
    defaultMessage: "Individual",
  }),
  ENTITY_LABEL: intl.formatMessage({
    defaultMessage: "Entity",
  }),
  NAME_LABEL: intl.formatMessage({
    defaultMessage: "Name",
  }),
  NAME_PLACEHOLDER: intl.formatMessage({
    defaultMessage: "Enter name",
  }),
  FIRST_NAME_LABEL: intl.formatMessage({
    defaultMessage: "First name",
  }),
  FIRST_NAME_PLACEHOLDER: intl.formatMessage({
    defaultMessage: "Enter first name",
  }),
  LAST_NAME_LABEL: intl.formatMessage({
    defaultMessage: "Last name",
  }),
  LAST_NAME_PLACEHOLDER: intl.formatMessage({
    defaultMessage: "Enter last name",
  }),
  COUNTRY_LABEL: intl.formatMessage({
    defaultMessage: "Country of residence/incorporation",
  }),
  COUNTRY_PLACEHOLDER: intl.formatMessage({
    defaultMessage: "Select country",
  }),
  EXCHANGE_LABEL: intl.formatMessage({
    defaultMessage: "Exchange",
  }),
  EXCHANGE_PLACEHOLDER: intl.formatMessage({
    defaultMessage: "Select exchange",
  }),
  WITHDRAWAL_ATTESTATION_ERROR: intl.formatMessage({
    defaultMessage:
      "We currently do not support transfers to this Exchange. Please send to a supported Exchange or Self-Hosted Wallet.",
  }),
});
