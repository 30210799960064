import {
  BANK_REGISTRATION_ROUTE,
  RegisterBankResponse,
  RtpBankRegistrationForm,
  RtpFundingInstructions,
  SUPPORTED_BANKS_ROUTE,
  SupportedBanks,
} from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/constants";
import { getInstructionsRoute } from "@gemini-ui/pages/settings/BankSettings/components/AddWireFundingSourceFlow/RtpFlow/utils";
import axios from "@gemini-ui/services/axios";
import { HEADERS } from "@gemini-ui/services/constants";

export const getSupportedBanks = () => axios.get<{ supportedBanks: SupportedBanks }>(SUPPORTED_BANKS_ROUTE);
export const registerBank = ({ accountNumber, nameOnAccount, bankCode, bic }: RtpBankRegistrationForm) => {
  const data = {
    bankAccountNumber: accountNumber,
    nameOnAccount,
    bankName: bankCode,
    bic,
    fastEnabled: true,
  };
  return axios.post<{ data: RegisterBankResponse }>(BANK_REGISTRATION_ROUTE, data);
};

export const getRtpFundingInstructions = (subaccountHashid: string, currency: string) =>
  axios.post<{ data: RtpFundingInstructions }>(getInstructionsRoute(currency), {
    headers: { [HEADERS.ACCOUNT_ID]: subaccountHashid },
  });
