import { useState } from "react";
import * as Sentry from "@sentry/browser";
import { useEffectOnce } from "react-use";
import { optimizelyClient } from "@gemini-ui/analytics";
import { OPTIMIZELY_FEATURE_FLAGS } from "@gemini-ui/constants/featureFlags";
import { usePageData } from "@gemini-ui/contexts";
import { getIsEuInboundEnabled, getIsUkInboundEnabled } from "@gemini-ui/pages/transfers/utils";
import axios from "@gemini-ui/services/axios";

export const useGetAwaitingOwnershipAttestations = (shouldFetchAttestations = true) => {
  const {
    templateProps: { account },
  } = usePageData();
  const isSingaporeMasEnabled = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.SINGAPORE_MAS_TRANSFER_CONTROL_WEB
  );
  const isUkTravelRuleEnabled = getIsUkInboundEnabled(account.geminiEntity);
  const isEuTravelRuleEnabled = getIsEuInboundEnabled(account.geminiEntity);
  const [canShowTransferControlAlert, setCanShowTransferControlAlert] = useState(
    Boolean(
      (isSingaporeMasEnabled && account?.hasDepositsAwaitingAttestation) ||
        (isUkTravelRuleEnabled && account?.hasUkDepositsAwaitingAttestation) ||
        (isEuTravelRuleEnabled && account?.hasEuDepositsAwaitingAttestation)
    )
  );
  const [hasMultipleAttestations, setHasMultipleAttestations] = useState(false);
  const [loadingHasMultipleAttestations, setLoadingHasMultipleAttestations] = useState(true);
  const [singleAttestation, setSingleAttestation] = useState(null);

  const generalTravelRuleUrl = optimizelyClient.isFeatureEnabled(
    OPTIMIZELY_FEATURE_FLAGS.EU_TRAVEL_RULE_EMPLOYEE_BYPASS
  )
    ? jsRoutes.controllers.order.HistoryController.getAwaitingOwnershipAttestationsGeneric().url
    : jsRoutes.controllers.order.HistoryController.getAwaitingUkOwnershipAttestations().url;
  const ownershipAttestationUrl =
    isUkTravelRuleEnabled || isEuTravelRuleEnabled
      ? generalTravelRuleUrl
      : jsRoutes.controllers.order.HistoryController.getAwaitingOwnershipAttestations().url;

  const getAwaitingOwnershipAttestations = async () => {
    if (!shouldFetchAttestations) {
      return;
    }
    try {
      const resp = await axios.get(ownershipAttestationUrl);
      if (resp?.data?.entries.length > 1) {
        setHasMultipleAttestations(true);
      } else if (resp?.data?.entries.length === 0 && canShowTransferControlAlert) {
        setCanShowTransferControlAlert(false);
      }

      if (resp?.data?.entries.length === 1) {
        setSingleAttestation(resp?.data?.entries[0]);
      }
    } catch (error) {
      Sentry.captureException(error);
    } finally {
      setLoadingHasMultipleAttestations(false);
    }
  };

  useEffectOnce(() => {
    getAwaitingOwnershipAttestations();
  });

  return {
    canShowTransferControlAlert,
    getAwaitingOwnershipAttestations,
    isSingaporeMasEnabled,
    isUkTravelRuleEnabled,
    isEuTravelRuleEnabled,
    hasMultipleAttestations,
    loadingHasMultipleAttestations,
    singleAttestation,
  };
};
