import { CurrencyShortNameCrypto } from "@gemini-common/scripts/constants/currencies";
import { LazyModalWrapper, MODAL_TYPES } from "@gemini-ui/components/LazyLoading/LazyModalWrapper";
import { CryptoDepositEntrypoint } from "@gemini-ui/components/Transfer/CryptoDepositFlow/types";

const CryptoDepositFlow = LazyModalWrapper(() => import("./index"));

interface Props {
  isOpen: boolean;
  onClose: () => void;
  preSelectedCrypto?: CurrencyShortNameCrypto;
  entrypoint?: CryptoDepositEntrypoint;
}

function LazyLoadCryptoDepositFlow({ isOpen, onClose, preSelectedCrypto, entrypoint }: Props) {
  return (
    <CryptoDepositFlow
      isOpen={isOpen}
      onClose={onClose}
      modalType={MODAL_TYPES.Sheet}
      sheetConfigs={{
        side: "right",
        disableAnimation: true,
      }}
      preSelectedCrypto={preSelectedCrypto}
      entrypoint={entrypoint}
    />
  );
}

export default LazyLoadCryptoDepositFlow;
