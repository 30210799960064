import * as Sentry from "@sentry/browser";
import {
  aliasUser,
  EVENTS,
  initializeFacebookPixel,
  initializeSnapchat,
  PEOPLE,
  setPeopleProperty,
  trackBingEvent,
  trackBrazeEvent,
  trackGTMEvent,
  trackRedditEvent,
  trackSnapCreditCardEvent,
  trackTikTokEvent,
  trackTwitterCreditCardEvent,
} from "@gemini-ui/analytics";
import {
  BingCreditCardEvents,
  CreditAnalyticsEventLabels,
  CreditAnalyticsEvents,
  FacebookCreditCardEvents,
  RedditCreditCardEvent,
  TikTokCreditCardEvent,
  TwitterCreditCardEvents,
} from "@gemini-ui/analytics/constants/creditCard";
import { setUtmPeopleProperties } from "@gemini-ui/analytics/mixpanel";
import { snapEvents } from "@gemini-ui/analytics/snapchatTracking";
import {
  Application,
  ApplicationStatus,
  ApplicationStatusStateStateEnum,
  RewardCurrency,
} from "@gemini-ui/client/credit";

const SNAP_ID = "94c5e4e9-9051-44ed-a6ed-a987563b4aab";
const FB_ID = "3006003839611824";

interface ApplicationMeta extends Application {
  rewardCurrency?: RewardCurrency;
}
interface TrackEventCredit {
  applicationData?: ApplicationMeta;
  resData?: ApplicationStatus;
}

const TRACK = "track";

const trackApplicationStart = ({ applicationData }: TrackEventCredit) => {
  trackGTMEvent(CreditAnalyticsEvents.StartApplication);
  trackTwitterCreditCardEvent(TwitterCreditCardEvents.StartApplication);
  trackBingEvent(BingCreditCardEvents.ccStartApplication);
  trackSnapCreditCardEvent(snapEvents.ccApplicationStart, SNAP_ID, applicationData.email);
};

const trackBeginPrequal = ({ applicationData }: TrackEventCredit) => {
  const currentDate = new Date().toISOString();
  const eventId = Math.floor(Date.now() / 1000).toString();
  const PERSONAL = "Personal";
  trackTwitterCreditCardEvent(TwitterCreditCardEvents.StartCreditPrequal);
  fbq(
    TRACK,
    FacebookCreditCardEvents.ccPrequalSubmitted,
    {},
    { eventID: eventId },
    { external_id: applicationData.email }
  );
  aliasUser(applicationData.mixpanelId);
  setPeopleProperty({
    [PEOPLE.UNIQUE_ID]: applicationData.mixpanelId,
    [PEOPLE.CREATION_DATE]: currentDate,
    [PEOPLE.ACCOUNT_TYPE]: PERSONAL,
    [PEOPLE.COUNTRY]: "us",
    [PEOPLE.BRAZE_EXTERNAL_ID]: applicationData.mixpanelId,
  });
  setUtmPeopleProperties(applicationData.mixpanelId);
  trackSnapCreditCardEvent(snapEvents.addCart, SNAP_ID, applicationData.email);
};

const trackNewCustomerDetails = () => {
  trackGTMEvent(CreditAnalyticsEvents.Registration);
  setPeopleProperty({ [PEOPLE.REGISTERED_FROM_CREDIT]: true });
};

const trackConfirmNameAddress = ({ applicationData }: TrackEventCredit) => {
  trackGTMEvent(CreditAnalyticsEvents.ConfirmNameAndAddress);
  trackTwitterCreditCardEvent(TwitterCreditCardEvents.ConfirmNameAndAddress);
  trackBingEvent(BingCreditCardEvents.ccConfirmNameAddress);
  trackSnapCreditCardEvent(snapEvents.ccConfirmNameAndAddress, SNAP_ID, applicationData.email);
};

const trackEndPrequal = ({ resData, applicationData }: TrackEventCredit) => {
  const isApproved =
    resData.statusHistory[resData.statusHistory.length - 1].state === ApplicationStatusStateStateEnum.SoftPullApproved;
  const { name, properties } = EVENTS.CC_END_CREDIT_PREQUAL;
  trackBrazeEvent(name, {
    [properties.IS_APPROVED]: isApproved,
    [properties.DENIAL_REASON]: !isApproved ? resData.statusHistory[resData.statusHistory.length - 1].state : null,
  });
  trackGTMEvent(CreditAnalyticsEvents.CompletePrequal, {
    eventLabel: isApproved ? CreditAnalyticsEventLabels.Approved : CreditAnalyticsEventLabels.Denied,
  });
  if (isApproved) {
    trackBingEvent(BingCreditCardEvents.ccPrequalApproved);
    trackTwitterCreditCardEvent(TwitterCreditCardEvents.CompleteCreditPrequal);
    trackSnapCreditCardEvent(snapEvents.signUp, SNAP_ID, applicationData.email);
  }
  setPeopleProperty({
    [PEOPLE.STATE]: applicationData.address.state,
  });
};

const trackEndHardPull = ({ applicationData, resData }: TrackEventCredit) => {
  const isApproved =
    resData.statusHistory[resData.statusHistory.length - 1].state === ApplicationStatusStateStateEnum.OfferExtended;
  trackGTMEvent(CreditAnalyticsEvents.CompleteHardpull);
  if (isApproved) {
    trackSnapCreditCardEvent(snapEvents.placeTrade, SNAP_ID, applicationData.email);
  }

  if (window.nid) window.nid("applicationSubmit");
};

const trackRewardChosen = () => {
  trackTwitterCreditCardEvent(TwitterCreditCardEvents.ccApplicationComplete);
  trackBingEvent(BingCreditCardEvents.ccApplicationComplete);
  trackTikTokEvent(TikTokCreditCardEvent.ccApplicationComplete);
  trackRedditEvent(RedditCreditCardEvent.ccApplicationComplete);
};

const creditAnalyticsTracking = {
  [EVENTS.CC_BEGIN_CREDIT_PREQUAL.name]: trackBeginPrequal,
  [EVENTS.CC_END_CREDIT_PREQUAL.name]: trackEndPrequal,
  [EVENTS.CC_END_CREDIT_HARDPULL.name]: trackEndHardPull,
  [EVENTS.CC_CREDIT_NEW_USER_EMAIL.name]: trackNewCustomerDetails,
  [EVENTS.CC_CREDIT_EXISTING_CONFIRM_INFO.name]: trackConfirmNameAddress,
  [EVENTS.CC_START_APPLICATION.name]: trackApplicationStart,
  [EVENTS.CC_CHOOSE_CRYPTO.name]: trackRewardChosen,
};

export const initPixelTracking = (email: string) => {
  if (!window.fbq) {
    initializeFacebookPixel(email, FB_ID);
  }

  if (!window.snaptr) {
    initializeSnapchat(email, SNAP_ID);
  }

  Sentry.setUser({ email });
};

export const trackAnalyticsEvent = ({
  trackingEvent,
  properties,
}: {
  trackingEvent: string;
  properties?: TrackEventCredit;
}) => {
  try {
    creditAnalyticsTracking[trackingEvent]({ ...properties });
  } catch (e) {
    Sentry.captureException(e);
  }
};
